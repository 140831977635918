import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

// Components
import {
  // TextInput,
  // SelectInput,
  Button,
  utils,
} from 'ui-library-unlocker';

import FileInput from '../../../../components/molecules/FileInput/FileInput';
import Modal from '../../../../components/molecules/Modal/Modal';

// utils
// import { displayError, isFieldValid } from '../../../../utils/forms/form';
import { hideModal } from '../../../../utils/modal';
import { getDocumentSchema, documentInitialValues } from '../documentSchema';

// Hooks
import useFileUpload from '../../../../hooks/useFileUpload';
import { useAppContext } from '../../../../store/context';

// Services
import { createCompanyDocument } from '../../../../services/company';

import styles from './AddCompanyDocModal.module.scss';

const DOCUMENT_TYPE = 'userDocuments';

function AddCompanyDocModal({
  modalId,
  companyUID,
  refetchDocumentList,
  fileType,
  onClose,
}) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();

  const [isHugeDocument, setHugeDocument] = useState(false);

  const validationSchema = useMemo(() => {
    if (uiBuilders) {
      const documentType = uiBuilders['/person/ui'].documentTypePMO;
      return getDocumentSchema({ documentType: Object.keys(documentType) });
    }
    return getDocumentSchema({ documentType: [] });
  }, [uiBuilders]);

  const initialValues = useMemo(() => {
    if (user) {
      return {
        ...documentInitialValues,
        userUid: user?.username,
      };
    }
    return documentInitialValues;
  }, [user]);

  const resetDocumentModal = () => {
    hideModal(modalId);
    setFile(null);
    setHugeDocument(false);
    formik.resetForm();
  };

  const createDocumentCb = async (documentUid, fileName) => {
    const createdDocument = await createCompanyDocument({
      type: fileType,
      name: fileName,
      documentUid,
      companyUID,
    });
    refetchDocumentList();
    resetDocumentModal();
    return createdDocument;
  };

  const [{ uploadFile, setFile }, {
    file, isUploading,
  }] = useFileUpload(createDocumentCb);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'document_added',
            entityType: 'company',
            fileType: values?.type,
          },
        });
        uploadFile({
          ...values,
          type: DOCUMENT_TYPE,
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  const handleFileSelect = (e) => {
    const f = e?.target?.files[0];

    if (f?.size > 10000000) {
      utils.toast.error(t('global.form.errors.file.tooLarge'));
      setHugeDocument(true);
      return;
    }

    if (f) {
      const setFileFields = (image = null) => {
        const dim = !image
          ? {}
          : {
            width: image.naturalWidth,
            height: image.naturalHeight,
          };

        formik.setFieldValue('filename', f?.name);
        formik.setFieldValue('metadata', {
          documentSize: f?.size,
          extension: f?.type?.split('/')[1],
          ...dim,
        });
        setFile(f);

        if (image) image.removeEventListener('load', setFileFields);
      };

      if (f?.type === 'application/pdf') {
        const reader = new FileReader();
        reader.onload = () => setFileFields(null);
        reader.readAsText(f);
      } else {
        const image = new Image();
        image.src = URL.createObjectURL(f);
        image.addEventListener('load', () => setFileFields(image));
      }
    } else {
      setFile(null);
      formik.setFieldValue('filename', '');
      formik.setFieldValue('metadata', null);
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    resetDocumentModal();
  }, [onClose, resetDocumentModal]);

  return (
    <Modal
      className={styles.modal}
      id={modalId}
      title={isHugeDocument
        ? t('global.documents.addDocumentForm.errors.size.title')
        : t(`global.documents.addDocumentForm.titles.${fileType}`)}
      size="large"
      onClose={handleClose}
    >
      {!isHugeDocument ? (
        <form onSubmit={formik.handleSubmit} className={styles.addForm}>
          <FileInput
            className="m-t-25"
            id="tenant-file"
            name="tenant-file"
            label={t('global.documents.addDocumentForm.fields.file')}
            help={t('global.documents.addDocumentForm.fileHelp')}
            accept="image/jpg,image/png,image/jpeg,application/pdf"
            value={file}
            onChange={handleFileSelect}
          />
          <div className={styles.submitBtn}>
            <Button
              type="submit"
              loading={isUploading}
              className="m-t-25"
              label={t('global.documents.addDocumentForm.submit')}
            />
          </div>
        </form>
      ) : (
        <div className={styles.hugeDocument}>
          <p className={styles.errorModalSubtitle}>{t('global.documents.addDocumentForm.errors.size.message')}</p>
          <div className={styles.submitBtn}>
            <Button
              className="m-t-25"
              label={t('global.documents.addDocumentForm.errors.size.action')}
              onClick={() => {
                formik.resetForm();
                setFile(null);
                setHugeDocument(false);
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

AddCompanyDocModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  companyUID: PropTypes.string.isRequired,
  refetchDocumentList: PropTypes.func,
  fileType: PropTypes.string,
  onClose: PropTypes.func,
};

AddCompanyDocModal.defaultProps = {
  refetchDocumentList: () => {},
  fileType: null,
  onClose: () => {},
};

export default AddCompanyDocModal;
