import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import {
  Picto, Tag, utils,
} from 'ui-library-unlocker';

// Components
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../../components/organisms/DataTable/DataTable';

// Services
import { getUsersFromAdmin } from '../../../services/admin';
import { impersonate } from '../../../services/identity';

import styles from './UserList.module.scss';

function UsersListView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [tooltipOpen, setTooltipOpen] = useState(null);

  useEffect(() => {
    document.title = t('user.browserTitle');
  }, [t]);

  const {
    mutate: postImpersonateMutate,
    isLoading: postImpersonateIsLoading,
  } = useMutation({
    mutationKey: ['impersonate'],
    mutationFn: impersonate,
    onSettled: () => {
      setTooltipOpen(null);
    },
  });

  const getStatusStyles = useCallback((status) => {
    switch (status) {
      case 'enabled':
        return 'secondary-outline';
      case 'pending':
        return 'secondary';
      case 'disabled':
        return 'error';
      default:
        return 'error';
    }
  }, []);

  const getOnboardingVariant = useMemo(() => (s) => {
    switch (s) {
      case 'pending':
      case 'enrolment_requested':
      case 'enrolment_pending':
      case 'enrolment_challenged':
        return 'tertiary';
      case 'profile_done':
        return 'primary';
      case 'enrolment_denied':
      case 'no_profile':
        return 'error';
      case 'enrolment_bypassed':
      case 'completed':
        return 'secondary-outline';
      default:
        return 'error';
    }
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('user.table.columns.id'),
      accessorKey: 'unlockerId',
      size: 100,
    },
    {
      header: t('user.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('user.table.columns.name'),
      accessorKey: 'lastName',
      minSize: 150,
      cell: ({ row: { original: { firstName, lastName } } }) => (
        <span>
          {firstName}
          {' '}
          {lastName}
        </span>
      ),
    },
    {
      header: t('user.table.columns.phone'),
      accessorKey: 'phoneNumber',
      size: 1,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            phoneNumber, phoneCountryCode, mobilePhoneNumber, mobilePhoneCountryCode,
          },
        },
      }) => (
        <div>
          <p className="p-2-500">
            {utils.getCompletePhoneNumber({ phone: mobilePhoneNumber, countryCode: mobilePhoneCountryCode })}
          </p>
          {phoneNumber != null ? (
            <p className="p-2-500">
              {utils.getCompletePhoneNumber({ phone: phoneNumber, countryCode: phoneCountryCode })}
            </p>
          ) : null}
        </div>
      ),
    },
    {
      header: t('user.table.columns.status.title'),
      accessorKey: 'status',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { status, underPendingInvitation } } }) => (
        <div>
          <Tag
            label={t(underPendingInvitation
              ? 'global.personStatus.isPendingInvitation'
              : `global.personStatus.${status}`)}
            size="medium"
            variant={underPendingInvitation ? 'tertiary' : getStatusStyles(status)}
            wrap={!isMobile}
          />
        </div>
      ),
    },
    {
      header: t('user.table.columns.onboardingStatus'),
      accessorKey: 'onboardingStatus',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { onboardingStatus } } }) => (
        <Tag
          size="medium"
          variant={getOnboardingVariant(onboardingStatus)}
          label={t(`profile.profileState.${onboardingStatus}`)}
          wrap={!isMobile}
        />
      ),
    },
    {
      header: t('user.table.columns.type'),
      accessorKey: 'role',
      minSize: 1,
      enableSorting: false,
      cell: ({ row: { original: { role } } }) => (
        <span>
          <Tag
            label={t(`user.table.columns.role.${role}`)}
            variant={role === 'ROLE_USER' ? 'secondary-outline' : 'tertiary'}
            size="medium"
          />
        </span>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-user-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-user-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('global.listOptions.see'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/user/${row?.original?.uid}`);
                  },
                },
                {
                  id: 'impersonate',
                  label: t('global.listOptions.impersonate'),
                  icon: postImpersonateIsLoading ? (
                    <Picto icon="loading" width={24} color="var(--color-primary)" />
                  ) : (
                    <Picto icon="user" width={24} color="var(--color-primary)" />
                  ),
                  onHoverIcon: postImpersonateIsLoading ? (
                    <Picto icon="loading" width={24} color="var(--color-white)" />
                  ) : (
                    <Picto icon="user" width={24} color="var(--color-white)" />
                  ),
                  onClick: (e) => {
                    e.stopPropagation();
                    postImpersonateMutate(row?.original);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [
    t,
    tooltipOpen,
    postImpersonateIsLoading,
    getStatusStyles,
    getOnboardingVariant,
    postImpersonateMutate,
    navigate,
    isMobile,
  ]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'role',
      type: 'checkbox',
      title: t('user.table.columns.type'),
      options: [
        {
          value: 'ROLE_USER',
          label: t('user.table.columns.role.ROLE_USER'),
        },
        {
          value: 'ROLE_LESSOR',
          label: t('user.table.columns.role.ROLE_LESSOR'),
        },
      ],
    },
    {
      name: 'onboardingStatus',
      type: 'checkbox',
      title: t('user.table.columns.onboardingStatus'),
      options: [
        {
          value: 'pending',
          label: t('profile.profileState.pending'),
        },
        {
          value: 'profile_done',
          label: t('profile.profileState.profile_done'),
        },
        {
          value: 'enrolment_requested,enrolment_pending,enrolment_challenged,enrolment_denied',
          label: t('profile.profileState.enrolment_requested'),
        },
        {
          value: 'enrolment_bypassed,completed',
          label: t('profile.profileState.completed'),
        },
        {
          value: 'no_profile',
          label: t('profile.profileState.no_profile'),
        },
      ],
    },
  ], []);

  return (
    <div
      onClick={() => setTooltipOpen(null)}
      role="presentation"
      onKeyDown={null}
    >
      <div className={styles.heading}>
        <h1>{t('user.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('user.search')}
        getData={getUsersFromAdmin}
        queryKey="user-list"
        allFiltersOptions={allFiltersOptions}
      />
    </div>
  );
}

export default UsersListView;
