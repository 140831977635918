import { useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button, Picto, utils } from 'ui-library-unlocker';
import { useFlags } from 'launchdarkly-react-client-sdk';

import WithLogin from '../../components/templates/WithLogin/WithLogin';
import RegisterForm from '../../components/organisms/forms/RegisterForm/RegisterForm';
import BetaRegisterForm from '../../components/organisms/forms/RegisterForm/BetaRegisterForm';
import DiscoverLink from '../../components/atoms/DiscoverLink/DiscoverLink';

import styles from './Register.module.scss';

function RegisterView() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { beta } = useFlags();
  // LANDING, OWNER_FORM, PRO_FORM
  const [registerStep, setRegisterStep] = useState('LANDING');
  const { t } = useTranslation();
  const { hash } = useLocation();

  useEffect(() => {
    document.title = t('register.browserTitle');
  }, [t]);

  const renderContentHeading = useCallback(() => (
    <div>
      {beta && (
        <div className={utils.cn([styles.userSection, styles.tenant, styles.userTenantHeading])}>
          <p className={styles.userTitle}>
            {t('register.tenant.youAreTitle')}
          </p>
          <p className={styles.userAction}>
            {t('register.tenant.action')}
          </p>
          <div className={styles.userCtas}>
            <a
              className={styles.storePill}
              href="https://apps.apple.com/fr/app/unlocker/id1667221898"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/cta-app-store.png"
                alt="Download on the App Store"
              />
            </a>
            <a
              className={styles.storePill}
              href="https://play.google.com/store/apps/details?id=com.unlockerapp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="m-l-20"
                src="/images/cta-google-play.png"
                alt="Get it on Google Play"
              />
            </a>
          </div>
        </div>
      )}
      <h1 className={styles.title}>
        {beta ? t('register.titleBeta') : t('register.title')}
      </h1>
      <Picto
        width={196}
        color="var(--color-secondary)"
        icon="trait-login-title"
      />
      <p className={styles.subTitle}>
        {beta ? t('register.subTitleBeta') : t('register.subTitle')}
      </p>
    </div>
  ), []);

  const renderContentLeft = useCallback(() => (
    <div>
      <div className={styles.contentLeft}>
        The key to simplicity
        <Picto
          className={styles.circle}
          width={204}
          icon="rond-1"
        />
        <Picto
          className={styles.line1}
          width={383}
          color="var(--color-secondary)"
          icon="trait-login-1"
        />
        <Picto
          className={styles.line2}
          width={296}
          color="var(--color-secondary)"
          icon="trait-login-2"
        />
      </div>
    </div>
  ), []);

  const formType = hash === '#resend-code' ? 'resend-code' : 'register';

  return (
    <WithLogin
      contentLeft={renderContentLeft()}
      contentHeading={renderContentHeading()}
    >
      { (registerStep === 'LANDING' && formType === 'register') && (
        <div>
          {!beta && (
            <p className={styles.youAre}>
              {t('register.youAre')}
            </p>
          )}
          <div className={styles.userTypes}>
            {beta ? (
              <BetaRegisterForm />
            ) : (
              <>
                <div className={utils.cn([styles.userSection, styles.tenant])}>
                  <p className={styles.userTitle}>
                    {t('register.tenant.title')}
                  </p>
                  <p className={styles.userAction}>
                    {t('register.tenant.action')}
                  </p>
                  <div className={styles.userCtas}>
                    <a
                      className={styles.storePill}
                      href="https://apps.apple.com/fr/app/unlocker/id1667221898"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/cta-app-store.png"
                        alt="Download on the App Store"
                      />
                    </a>
                    <a
                      className={styles.storePill}
                      href="https://play.google.com/store/apps/details?id=com.unlockerapp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="m-l-20"
                        src="/images/cta-google-play.png"
                        alt="Get it on Google Play"
                      />
                    </a>
                  </div>
                </div>
                <div className={utils.cn([styles.userSection, styles.owner])}>
                  <p className={styles.userTitle}>
                    {t('register.owner.title')}
                  </p>
                  <p className={styles.userAction}>
                    {t('register.owner.action')}
                  </p>
                  <div className={styles.userCtas}>
                    <Button
                      onClick={() => setRegisterStep('OWNER_FORM')}
                      size="large"
                      blockLevel={isMobile}
                      variant="secondary"
                      label={t('register.owner.signUp')}
                    />
                  </div>
                </div>
                <div className={utils.cn([styles.userSection, styles.pro])}>
                  <p className={styles.userTitle}>
                    {t('register.pro.title')}
                  </p>
                  <p className={styles.userAction}>
                    {t('register.pro.action')}
                  </p>
                  <div className={styles.userCtas}>
                    <Button
                      onClick={() => setRegisterStep('PRO_FORM')}
                      size="large"
                      blockLevel={isMobile}
                      label={t('register.pro.signUp')}
                    />
                  </div>
                </div>
              </>
            )}
            <p className={styles.haveAccount}>
              {t('register.alreadyHaveAccount')}
              <Link to="/login">
                {t('register.signIn')}
              </Link>
            </p>
          </div>
        </div>
      ) }
      { (registerStep === 'OWNER_FORM' || formType === 'resend-code') && (
      <RegisterForm
        label={t('register.owner.title')}
        action={t('register.owner.action')}
        registrationType="OWNER_FORM"
      />
      )}
      { (registerStep === 'PRO_FORM') && (
      <RegisterForm
        label={t('register.pro.title')}
        action={t('register.pro.action')}
        registrationType="PRO_FORM"
      />
      ) }
      {!beta && (
        <div className="m-t-30 m-b-30 text-center">
          <DiscoverLink />
        </div>
      )}
    </WithLogin>
  );
}

export default RegisterView;
