import { useCallback } from 'react';
import TagManager from 'react-gtm-module';
import {
  WithHeader,
  Button,
  utils,
  Picto,
} from 'ui-library-unlocker';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  useNavigate, Navigate, Link, useParams,
} from 'react-router-dom';

import SearchView from '../Search';

import styles from './SearchPublicView.module.scss';

function SearchPublicView() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const renderNav = useCallback(() => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Button
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'login_from_map',
                  action: 'click',
                },
              });
              navigate('/login', { state: { from: window.location.pathname } });
            }}
            label={t('login.title')}
            size={isMobile ? 'small' : 'medium'}
          />
        </li>
      </ul>
    </nav>
  ), [isMobile, navigate, t]);

  if (localStorage.getItem('accessToken')) return <Navigate to={`/rechercher/${id || ''}`} />;

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className={utils.cn([
        styles.searchPublicView,
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >
      <SearchView />
      <footer className={styles.footer}>
        {!isMobile && (
        <>
          <Link to="/register" className={styles.footerLogo}>
            <Picto icon="logo-horizontal" width={160} color="var(--color-black)" />
          </Link>

          <div className={styles.socialNetworks}>
            <a href="https://www.facebook.com/profile.php?id=100085865975036" target="_blank" rel="noopener noreferrer">
              <Picto icon="facebook-frame" width={20} color="var(--color-black)" />
            </a>
            <a href="https://fr.linkedin.com/company/unlockerapp" target="_blank" rel="noopener noreferrer">
              <Picto icon="linkedin-frame" width={24} color="var(--color-black)" />
            </a>
          </div>
        </>
        )}

        <div className={styles.userCtas}>
          <a
            className={styles.storePill}
            href="https://apps.apple.com/us/app/unlocker/id1667221898"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/cta-app-store.png"
              alt="Download on the App Store"
            />
          </a>
          <a
            className={styles.storePill}
            href="https://play.google.com/store/apps/details?id=com.unlockerapp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="m-l-20"
              src="/images/cta-google-play.png"
              alt="Get it on Google Play"
            />
          </a>
        </div>
      </footer>
    </WithHeader>
  );
}

SearchPublicView.propTypes = {
};

SearchPublicView.defaultProps = {};

export default SearchPublicView;
