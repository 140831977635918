/* eslint-disable import/prefer-default-export */
import React from 'react';
import { toast as toastFn } from 'react-toastify';

import Picto from '../components/atoms/Picto/Picto';

const toast = {};

toast.info = (content = '', options = {}) => {
  toastFn.info(content, {
    ...options,
    icon: <Picto icon="info-circle" color="var(--color-secondary)" width={24} height={24} />,
  });
};

toast.error = (content, options) => {
  toastFn.error(content, {
    ...options,
    icon: <Picto icon="alert-circle" color="var(--color-primary)" width={24} height={24} />,
  });
};

toast.success = (content, options) => {
  toastFn.success(content, {
    ...options,
    icon: <Picto icon="tick-circle" color="var(--color-secondary)" width={24} height={24} />,
  });
};

toast.alert = (content, options) => {
  toastFn.error(content, {
    ...options,
    icon: <Picto icon="alert-circle" color="var(--color-primary)" width={24} height={24} />,
  });
};

toast.dismiss = (options) => {
  toastFn.dismiss(options);
};

export { toast };
