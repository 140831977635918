import axios from 'axios';

export const getStatusStyles = (status) => {
  switch (status) {
    case 'available':
      return {
        variant: 'secondary',
        icon: 'house-bulk',
      };
    case 'leased':
      return {
        variant: 'secondary-outline',
        icon: 'house-2',
      };
    case 'disabled':
      return {
        variant: 'error',
        icon: 'house-2',
      };
    case 'waiting_kyc':
      return {
        variant: 'error',
        icon: 'house-2',
      };
    case 'waiting_kyc_owner':
      return {
        variant: 'error',
        icon: 'house-2',
      };
    case 'waiting_kyc_manager':
      return {
        variant: 'error',
        icon: 'house-2',
      };
    default:
      return {
        variant: 'secondary-outline',
        icon: 'house-2',
      };
  }
};

export const getIpAddress = () => axios.get('https://api64.ipify.org?format=json');

export const formatPrice = (price) => {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
};

export const formatAddress = (address) => {
  if (!address) return 'Adresse indisponible';
  const {
    streetNumber,
    street,
    streetDetails,
    zipCode,
    city,
  } = address;

  return `${streetNumber ?? ''} ${street ?? ''} ${streetDetails ?? ''} ${zipCode ?? ''} ${city ?? ''}`;
};
