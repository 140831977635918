import { PAYMENT_AND_ACCOUNT_STATUS_LIST } from './constants';

export const getPaymentAndAccountStatusVariant = (status) => {
  switch (status) {
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED_MANUAL:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.NOTIFIED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.PENDING:
      return 'secondary-outline';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.IN_WIRING:
      return 'secondary';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_CHECK:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.EXPORTED:
      return 'tertiary';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SUCCESS:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.RECEIVED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SENT:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_WIRE:
      return 'success';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.CANCELED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.FAILED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.NOT_DOABLE:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULING_ERROR:
      return 'error';

    default:
      return 'primary';
  }
};
