/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import {
  Picto, Tag, Button,
} from 'ui-library-unlocker';
import { centsToEuro } from 'ui-library-unlocker/src/utils/money';

import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import TenantLeavePropertyModal from './TenantLeavePropertyModal';
import DataTable from '../../components/organisms/DataTable/DataTable';

// Hooks
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';

// Services
import { getTenants } from '../../services/tenant';
import { getTenantsFromAdmin } from '../../services/admin';

import { showModal } from '../../utils/modal';

import styles from './TenantList.module.scss';

const TENANT_LEAVE_PROPERTY_MODAL_ID = 'TENANT_LEAVE_PROPERTY_MODAL_ID';

function TenantListView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();
  const { context: { user } } = useAppContext();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [tenantLeaving, setTenantLeaving] = useState(null);

  useEffect(() => {
    document.title = t('tenant.browserTitle');
  }, [t]);

  const getStatusStyles = useCallback((status) => {
    switch (status) {
      case 'enabled':
        return 'secondary-outline';
      case 'pending':
        return 'secondary';
      case 'disabled':
        return 'error';
      default:
        return 'error';
    }
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('tenant.table.columns.id'),
      accessorKey: 'unlockerId',
      size: 100,
    },
    {
      header: t('tenant.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.name'),
      accessorKey: 'lastName',
      minSize: 100,
      cell: ({ row }) => (
        <div className={styles.name}>
          <span className={styles.firstName}>{row?.original?.firstName}</span>
          <span className={styles.lastName}>{row?.original?.lastName}</span>
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.property'),
      accessorKey: 'propertyName',
      size: 200,
      cell: ({ row }) => (
        <div>
          {row?.original?.propertyName != null ? (
            <div className={styles.nameAddress}>
              <Link to={`/property/${row.original.propertyUid}`} className={styles.name}>
                <p className="p-2-700">
                  {row.original.propertyName}
                  <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
                </p>
              </Link>
              <div className={styles.address}>
                {row.original.propertyName}
              </div>
            </div>
          ) : '-'}
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.price'),
      accessorKey: 'price',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => (
        <div>
          <span>{row?.original?.balance != null ? centsToEuro(row?.original?.balance) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status, underPendingInvitation } } }) => (
        <div>
          <Tag
            label={t(underPendingInvitation
              ? 'global.personStatus.isPendingInvitation'
              : `global.personStatus.${status}`)}
            size="medium"
            variant={underPendingInvitation ? 'tertiary' : getStatusStyles(status)}
          />
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.delegated'),
      accessorKey: 'delegated',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => {
        const userHasDelegation = (
          row?.original?.isDelegated
          && user?.username === row?.original?.delegatedTo
        );
        return (
          <div>
            <span>
              {userHasDelegation ? (
                <Tag
                  label={t('tenant.table.columns.delegated')}
                  size="medium"
                  variant="tertiary"
                />
              ) : ''}
            </span>
          </div>
        );
      },
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-tenant-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-tenant-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('global.listOptions.edit'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/tenant/${row?.original?.uid}`);
                  },
                },
                isUserRealEstateManager && row?.original?.propertyName != null ? {
                  id: 'terminate-lease',
                  label: t('global.listOptions.terminateLease'),
                  icon: <Picto icon="logout" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="logout" width={24} color="var(--color-white)" />,
                  onClick: () => {
                    setTenantLeaving(row?.original);
                    showModal(TENANT_LEAVE_PROPERTY_MODAL_ID);
                  },
                } : null,
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('tenant.table.columns.status'),
      options: [
        {
          value: 'active',
          label: t('tenant.table.status.active'),
        },
        {
          value: 'inactive',
          label: t('tenant.table.status.inactive'),
        },
      ],
    },
  ], []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <div className={styles.heading}>
        <h1>{t('tenant.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('tenant.search')}
        getData={isUserAdmin ? getTenantsFromAdmin : getTenants}
        queryKey="tenant-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={(
          <Link to="/tenant/add">
            <Button
              variant="primary"
              size="large"
              icon="plus"
            >
              {t('tenant.add')}
            </Button>
          </Link>
        )}
      />
      <TenantLeavePropertyModal id={TENANT_LEAVE_PROPERTY_MODAL_ID} tenant={tenantLeaving} />
    </div>
  );
}

export default TenantListView;
