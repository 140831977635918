import decode from 'jwt-decode';

export const getUserFromToken = (token) => {
  try {
    const user = decode(token);
    return user;
  } catch (error) {
    return null;
  }
};

export const doesUserHaveRole = (userRoles, roles) => {
  if (!userRoles || !roles) {
    return false;
  }

  return roles.some((role) => userRoles.includes(role));
};
