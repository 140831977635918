/* eslint-disable no-new */
import {
  useEffect,
  useRef,
  useState,
  useMemo,
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import {
  UnlockerLoader,
} from 'ui-library-unlocker';

import { useAppContext } from '../../../store/context';

// --- MAP ---
function MapComponent({
  center,
  zoom,
  children,
  id,
  className,
}) {
  const ref = useRef(null);
  const [clusterer, setClusterer] = useState();
  const [map, setMap] = useState();
  const { context: { googleMaps } } = useAppContext();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new googleMaps.maps.Map(ref.current, {
        center,
        zoom,
        mapId: process.env.REACT_APP_MAP_ID,
        disableDefaultUI: true,
      }));
    }

    if (map) {
      setClusterer(new MarkerClusterer({
        map,
        renderer: {
          render: ({ count, position }, stats) => {
            // change color if this cluster has more markers than the mean cluster
            const color = count > Math.max(10, stats.clusters.markers.mean)
              ? '#c71620'
              : '#173cc1';

            // create svg url with fill color
            const svg = window.btoa(`
            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
              <circle cx="120" cy="120" opacity="1" r="70" />
              <circle cx="120" cy="120" opacity=".3" r="90" />
              <circle cx="120" cy="120" opacity=".2" r="110" />
            </svg>`);

            // create marker using svg icon
            // eslint-disable-next-line no-undef
            return new googleMaps.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                // eslint-disable-next-line no-undef
                scaledSize: new googleMaps.maps.Size(45, 45),
              },
              label: {
                text: String(count),
                color: 'rgba(255,255,255,0.9)',
                fontSize: '13px',
              },
              // adjust zIndex to be above other markers
              zIndex: 1000 + count,
            });
          },
        },
      }));
    }

    return () => {
      if (map) {
        map.unbindAll(); // Assurez-vous de nettoyer les événements liés à la carte
      }
    };
  }, [ref, map]);

  return (
    <>
      <div ref={ref} id={id} className={className} />
      {// eslint-disable-next-line consistent-return
      Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map, clusterer });
        }
      })
      }
    </>
  );
}
MapComponent.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  zoom: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};
MapComponent.defaultProps = {
  children: null,
  className: null,
};
// ------------------------------

// --- MARKER ---
function Marker({
  position,
  children,
  onClick,
  clusterer,
}) {
  const rootRef = useRef();
  const markerRef = useRef();
  const { context: { googleMaps } } = useAppContext();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement('div');
      rootRef.current = createRoot(container);

      // eslint-disable-next-line no-undef
      markerRef.current = new googleMaps.maps.marker.AdvancedMarkerView({
        position,
        content: container,
      });
    }

    return () => {
      clusterer?.removeMarker(markerRef.current);
    };
  }, []);

  useEffect(() => {
    clusterer?.addMarker(markerRef.current);
  }, [clusterer]);

  useEffect(() => {
    markerRef.current.position = position;
  }, [position]);

  useEffect(() => {
    rootRef.current.render(children);
  }, [children]);

  useEffect(() => {
    const listener = markerRef.current.addListener('gmp-click', onClick);
    return () => {
      listener.remove();
    };
  }, [onClick]);

  return null;
}
Marker.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  children: PropTypes.node,
  onClick: PropTypes.func,
  clusterer: PropTypes.shape({
    addMarker: PropTypes.func,
    removeMarker: PropTypes.func,
  }),
};
Marker.defaultProps = {
  position: null,
  children: null,
  onClick: () => {},
  clusterer: null,
};
// ------------------------------

// --- CIRCLES ---
function Circle({
  center,
  map,
}) {
  const circleRef = useRef();
  const { context: { googleMaps } } = useAppContext();

  useEffect(() => {
    if (!circleRef.current && map) {
      // eslint-disable-next-line no-undef
      circleRef.current = new googleMaps.maps.Circle({
        strokeColor: '#173cc1',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#173cc1',
        fillOpacity: 0.35,
        center,
        map,
        radius: 1000,
      });
    }

    return () => {
      circleRef.current?.setMap(null);
    };
  }, [map]);

  return null;
}
Circle.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  map: PropTypes.shape({}),
};
Circle.defaultProps = {
  center: null,
  map: null,
};
// ------------------------------

const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <UnlockerLoader size={200} />;
    case Status.FAILURE:
      return <span>error</span>;
    default:
      return null;
  }
};

function Map({
  markers,
  center,
  zoom,
  id,
  className,
  circles,
}) {
  const { context: { googleMaps } } = useAppContext();

  const mapMarkers = useMemo(() => markers.map((marker) => (
    <Marker
      key={marker.id}
      position={marker.position}
    >
      {marker.content}
    </Marker>
  )), [markers]);

  const mapCircles = useMemo(() => circles.map((circle) => (
    <Circle
      key={circle.id}
      center={circle.position}
    />
  )), [circles]);

  return googleMaps && (
    <Wrapper
      id="__googleMapsScriptId"
      render={render}
    >
      <MapComponent center={center} zoom={zoom} id={id} className={className}>
        {mapMarkers}
        {mapCircles}
      </MapComponent>
    </Wrapper>
  );
}
Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    status: PropTypes.oneOf(Object.values(Status)),
    rentExcludingCharges: PropTypes.number,
  })),
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  zoom: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  circles: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  })),
};
Map.defaultProps = {
  markers: [],
  className: null,
  circles: [],
};

export default Map;
