/* eslint-disable max-len */
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { Picto, Tag, utils } from 'ui-library-unlocker';

import CompanyForm from '../../components/organisms/forms/CompanyForm/CompanyForm';
import CompanyDocuments from './CompanyDocuments/CompanyDocuments';

// Services
import { getCompany, getCompanyFromAdmin } from '../../services/company';

import styles from './CompanyEdit.module.scss';
import PersonPaymentAccount from '../../components/organisms/PersonPaymentAccount/PersonPaymentAccount';
import useRoles from '../../hooks/useRoles';

function CompanyEdit({ isEditing }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { isUserAdmin } = useRoles();

  const match = useMatch('/company/:id');

  useEffect(() => {
    switch (location.hash) {
      case '#documents':
        document.title = t('company.tabs.documents.browserTitle');
        break;
      case '#payment-account':
        document.title = t('company.tabs.paymentAccount.browserTitle');
        break;
      default:
        document.title = t('company.tabs.generalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const companyQuery = useQuery({
    queryKey: ['company-id', isEditing],
    queryFn: () => {
      if (isEditing && match?.params?.id !== 'add') {
        if (isUserAdmin) return getCompanyFromAdmin(match?.params?.id);
        return getCompany(match?.params?.id);
      }
      return null;
    },
    cacheTime: 0,
  });

  const tabs = useMemo(() => [
    {
      label: t('company.tabs.generalInfo.tabTitle'),
      title: t('company.tabs.generalInfo.title'),
      hash: '',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <CompanyForm isEditing={isEditing} companyQuery={companyQuery} />
      ),
      show: true,
    },
    {
      label: t('company.tabs.documents.tabTitle'),
      title: t('company.tabs.documents.title'),
      hash: 'documents',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <CompanyDocuments companyData={companyQuery?.data || {}} refetchCompany={companyQuery.refetch} />
      ),
      show: true,
    },
    {
      label: t('company.tabs.paymentAccount.tabTitle'),
      title: t('company.tabs.paymentAccount.title'),
      hash: 'payment-account',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <PersonPaymentAccount entity="company" dataQuery={companyQuery} />
      ),
      show: isUserAdmin,
    },
  ], [t, location, isEditing, companyQuery, isUserAdmin]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    const tab = tabs.find((cur) => cur.hash === hash && cur.show);
    return tab || tabs[0];
  }, [tabs, location]);

  const {
    legalName, onboardingStatus,
  } = companyQuery?.data?.data || {};

  const statusVariant = useMemo(() => {
    if (!onboardingStatus) return null;
    switch (onboardingStatus) {
      case 'pending':
      case 'enrolment_requested':
      case 'enrolment_pending':
      case 'enrolment_challenged':
      case 'enrolment_denied':
        return 'tertiary';

      case 'profile_done':
        return 'primary';

      case 'blocked':
        return 'error';

      case 'enrolment_bypassed':
      case 'completed':
        return 'success';

      default:
        return 'tertiary';
    }
  }, [onboardingStatus]);

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1>{isEditing ? t('company.crud.editTitle') : t('company.crud.addTitle')}</h1>
          { isEditing && companyQuery?.data && (
          <div className={utils.cn(['m-t-20', styles.editDetails])}>
            <h2 className="t-h2-500">{legalName}</h2>
            <Tag
              label={t(`global.companyStatus.${onboardingStatus}`)}
              size="medium"
              variant={statusVariant}
            />
          </div>
          )}
        </div>
        {isEditing ? (
          <ul className={styles.tabList}>
            {tabs.map((tab) => {
              if (!tab.show) return null;
              const isActive = tab.hash === currentTab.hash;

              return (
                <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash}>
                  <Link to={`#${tab.hash}`}>{tab.label}</Link>
                  {isActive && tab.activePicto}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

CompanyEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default CompanyEdit;
