/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useCallback, useMemo, useImperativeHandle, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  utils,
  TextInput,
  Button,
  UnlockerLoader,
  SelectInput,
} from 'ui-library-unlocker';

// Components
import AddressInput from '../../../molecules/AddressInput/AddressInput';

// Hooks
import useScroll from '../../../../hooks/useScroll';

// Services
import {
  createBankingIdentity,
  updateBankingIdentity,
  createTenantBankingIdentity,
  updateTenantBankingIdentity,
} from '../../../../services/bankingIdentity';

// Utils
import {
  bankingIdentitySchema,
  bankingIdentityInitialValues,
} from '../../../../utils/forms/bankingIdentitySchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';

import styles from './BankingIdentityForm.module.scss';

const BankingIdentityForm = forwardRef(({
  isEditing,
  bankingIdentityQuery,
  isReadOnly,
  onFormSubmitReplace,
  hideCancelBtn,
  noLoading,
  onCancel,
  delegatedTenantUID,
  onAddSuccess,
  setSelectedDelegatedTenantUID,
  delegatedTenants,
}, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { scrollContentToTop } = useScroll();

  const match = useMatch('/banking-identities/:id');

  const {
    data: bankingIdentityData,
    error: bankingIdentityError,
    isFetched: bankingIdentityFetched,
    refetch: bankingIdentityRefetch,
  } = bankingIdentityQuery || {};

  const initialValues = useMemo(() => {
    if (bankingIdentityError) return bankingIdentityData;
    if (bankingIdentityData) {
      return bankingIdentityData.data;
    }
    return bankingIdentityInitialValues;
  }, [bankingIdentityData]);

  const formik = useFormik({
    initialValues,
    validationSchema: bankingIdentitySchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!isReadOnly) {
        const valuesToSubmit = {
          ...values,
          ...(values.address || {}),
        };
        if (onFormSubmitReplace) {
          formik.setSubmitting(false);
          onFormSubmitReplace(valuesToSubmit);
        } else {
          bankingIdentityMutation.mutate(valuesToSubmit);
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    resetForm: () => {
      formik.resetForm();
    },
  }), [formik]);

  const bankingIdentityMutation = useMutation({
    mutationFn: (data) => {
      if (isEditing) {
        if (delegatedTenantUID) return updateTenantBankingIdentity(delegatedTenantUID, match?.params?.id, data);
        return updateBankingIdentity(match?.params?.id, data);
      }
      if (delegatedTenantUID) return createTenantBankingIdentity(delegatedTenantUID, data);
      return createBankingIdentity(data);
    },
    onSuccess: ({ response, status/* , data */ }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('bankingIdentity.crud.addSuccess'));
          if (delegatedTenantUID) {
            onAddSuccess?.();
            onCancel?.(formik.resetForm);
          } else {
            // navigate(`/banking-identities/${data?.uid}`);
            navigate('/banking-identities');
          }
          break;
        case 204:
          // handle delegatedTenantUID in future
          utils.toast.success(t('bankingIdentity.crud.editSuccess'));
          bankingIdentityRefetch().then(() => {
            scrollContentToTop();
          });
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          formik.setErrors(err.response.data?.errors);
          if (err.response.data?.message === 'PAYMENT_IDENTITY_IDENTITY_CONFLICT_EXCEPTION') {
            utils.toast.error(t(`bankingIdentity.crud.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.generic'));
          }
          break;
        }
        case 500: {
          utils.toast.error(t('global.form.errors.global'));
          break;
        }
        default: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
      }
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const handleCancel = useCallback(() => {
    if (onCancel) {
      return onCancel(formik.resetForm);
    }
    utils.toast.info(t('bankingIdentity.crud.cancelMessage'));
    return scrollContentToTop();
  }, [formik, t]);

  const handleTenantChange = useCallback((item) => {
    setSelectedDelegatedTenantUID(item.value);
  }, [setSelectedDelegatedTenantUID]);

  const tenantOptions = useMemo(() => {
    if (delegatedTenants.length) {
      return delegatedTenants.map((tenant) => ({
        label: `${tenant.firstName} ${tenant.lastName}`,
        value: tenant.uid,
      }));
    }
    return [];
  }, [delegatedTenants]);

  if (!bankingIdentityFetched && !noLoading) return <UnlockerLoader />;

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
        {tenantOptions.length > 1 && (
          <SelectInput
            className={utils.cn(['m-b-25', styles.fullInput])}
            id="tenant"
            name="tenant"
            label={t('bankingIdentity.crud.form.tenant')}
            options={tenantOptions}
            onChange={handleTenantChange}
            onBlur={formik.handleBlur}
            value={tenantOptions.find((tenantItem) => tenantItem.value === delegatedTenantUID)}
          />
        )}
        <TextInput
          type="text"
          id="label"
          name="label"
          className={styles.fullInput}
          label={t('bankingIdentity.crud.form.label')}
          error={displayError(t, formik, 'label')}
          valid={isFieldValid(formik, 'label', null, initialValues?.label)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.label}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="firstName"
          name="firstName"
          className={utils.cn([styles.fullInput, 'm-t-25'])}
          label={t('bankingIdentity.crud.form.firstName')}
          error={displayError(t, formik, 'firstName')}
          valid={isFieldValid(formik, 'firstName', null, initialValues?.firstName)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="lastName"
          name="lastName"
          className={utils.cn([styles.fullInput, 'm-t-25'])}
          label={t('bankingIdentity.crud.form.lastName')}
          error={displayError(t, formik, 'lastName')}
          valid={isFieldValid(formik, 'lastName', null, initialValues?.lastName)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="iban"
          name="iban"
          className={utils.cn([styles.fullInput, 'm-t-25'])}
          label={t('bankingIdentity.crud.form.iban')}
          error={displayError(t, formik, 'iban')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.iban}
          disabled={isReadOnly}
        />
        <AddressInput
          id="address"
          name="address"
          className="m-t-25"
          label={t('bankingIdentity.crud.form.address')}
          error={displayError(t, formik, 'address')}
          valid={isFieldValid(formik, 'address', null, initialValues?.address)}
          onAddressSelect={(value) => formik.setFieldValue('address', value)}
          value={formik.values.address || null}
          disabled={isReadOnly}
        />
        {!isReadOnly && (
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              loading={formik.isSubmitting}
              label={t('global.validate')}
            />
            {!hideCancelBtn && (
              <p
                tabIndex={0}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                onKeyDown={null}
                className={styles.cancel}
                onClick={handleCancel}
              >
                {t('global.cancel')}
              </p>
            )}
          </div>
        )}
      </form>
    </div>
  );
});

BankingIdentityForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  bankingIdentityQuery: PropTypes.shape(),
  isReadOnly: PropTypes.bool,
  onFormSubmitReplace: PropTypes.func,
  hideCancelBtn: PropTypes.bool,
  noLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  delegatedTenantUID: PropTypes.string,
  onAddSuccess: PropTypes.func,
  setSelectedDelegatedTenantUID: PropTypes.func,
  delegatedTenants: PropTypes.arrayOf(PropTypes.shape()),
};

BankingIdentityForm.defaultProps = {
  bankingIdentityQuery: null,
  isReadOnly: false,
  onFormSubmitReplace: null,
  hideCancelBtn: false,
  noLoading: false,
  onCancel: null,
  delegatedTenantUID: null,
  onAddSuccess: null,
  setSelectedDelegatedTenantUID: null,
  delegatedTenants: [],
};

export default BankingIdentityForm;
