import { useMemo } from 'react';

import client from '../services/_algolia';

const useAlgolia = (filters) => {
  const AlgoliaSearch = useMemo(() => client.initIndex(process.env.REACT_APP_ALGOLIA_PROPERTIES_INDEX), []);

  const facetFiltersOmitted = useMemo(() => {
    const filtersToBuild = [['status:available']];

    if (filters?.showLeased) {
      filtersToBuild?.[0].push('status:leased');
    }

    const typeFilters = [];
    (filters?.type || []).forEach((type) => {
      typeFilters.push(`type:${type}`);
    });
    if (typeFilters?.length > 0) {
      filtersToBuild.push(typeFilters);
    }

    return filtersToBuild;
  }, [filters]);

  const facetFilters = useMemo(() => {
    const filtersToBuild = [...facetFiltersOmitted];

    if (filters?.city) {
      filtersToBuild?.push([`address.city:${filters?.city}`]);
    }

    return filtersToBuild;
  }, [filters, facetFiltersOmitted]);

  const numericFilters = useMemo(() => {
    const filtersToBuild = [];

    const numberOfRoomsFilter = [];
    (filters?.numberOfRooms || []).forEach((number) => {
      numberOfRoomsFilter.push(`numberOfRooms${number >= 4 ? '>=' : '='}${number}`);
    });
    if (numberOfRoomsFilter?.length > 0) {
      filtersToBuild.push(numberOfRoomsFilter);
    }

    if (filtersToBuild?.length > 0) return `(${filtersToBuild.join(' OR ')})`;
    return undefined;
  }, [filters]);

  const searchCity = ({ city, withFilters }) => AlgoliaSearch.searchForFacetValues('address.city', city, {
    facetFilters: withFilters ? facetFiltersOmitted : undefined,
    numericFilters: withFilters ? numericFilters : undefined,
  });

  const search = ({ page = 0, hitsPerPage }) => AlgoliaSearch.search('', {
    facetFilters,
    numericFilters,
    hitsPerPage,
    page,
  });

  const getObject = ({ objectID }) => AlgoliaSearch.getObject(objectID);

  return {
    AlgoliaSearch,
    searchCity,
    search,
    getObject,
  };
};

export default useAlgolia;
