import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Picto, Tag,
} from 'ui-library-unlocker';

// Components
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../../components/organisms/DataTable/DataTable';

// Services
import { getPaymentAccounts } from '../../../services/paymentAccount';

import styles from './PaymentAccount.module.scss';

function PaymentAccountList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tooltipOpen, setTooltipOpen] = useState(null);

  useEffect(() => {
    document.title = t('paymentAccount.browserTitle');
  }, [t]);

  const getStatusVariant = useMemo(() => (s) => {
    switch (s) {
      case 'closed':
        return 'secondary-outline';
      case 'opened':
        return 'secondary';
      case 'blocked':
      case 'debit_blocked':
      case 'credit_blocked':
        return 'error';
      case 'pending':
        return 'tertiary';
      default:
        return 'error';
    }
  }, []);

  const getEnrolmentVariant = useMemo(() => (s) => {
    switch (s) {
      case 'to_review':
        return 'tertiary';
      case 'pending':
      case 'submitted':
      case 'escalated':
        return 'secondary';
      case 'validated':
        return 'success';
      case 'refused':
      case 'blocked':
        return 'error';
      case 'waiting_for_modification':
        return 'secondary-outline';
      case 'not_applicable':
        return 'primary';
      default:
        return 'error';
    }
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('paymentAccount.table.columns.id'),
      accessorKey: 'unlockerID',
      size: 100,
    },
    {
      header: t('paymentAccount.table.columns.name'),
      accessorKey: 'name',
      minSize: 150,
      cell: ({
        row: {
          original: {
            firstName, lastName, isCompany, companyName,
          },
        },
      }) => (
        <span>
          {isCompany ? companyName : `${firstName} ${lastName}`}
        </span>
      ),
    },
    {
      header: t('paymentAccount.table.columns.type'),
      accessorKey: 'isCompany',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <Tag
          size="small"
          variant={original?.isCompany ? 'secondary' : 'primary'}
          label={t(`paymentAccount.table.type.${original?.isCompany ? 'company' : 'person'}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.email'),
      accessorKey: 'email',
      size: 100,
    },
    {
      header: t('paymentAccount.table.columns.ezyNessNb'),
      accessorKey: 'internalAccount',
      size: 100,
    },
    {
      header: t('paymentAccount.table.columns.enrolmentStatus'),
      accessorKey: 'enrolmentStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { enrolmentStatus } } }) => (
        <Tag
          size="small"
          variant={getEnrolmentVariant(enrolmentStatus)}
          label={t(`paymentAccount.table.enrolmentStatus.${enrolmentStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.accountStatus'),
      accessorKey: 'accountStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { accountStatus } } }) => (
        <Tag
          size="small"
          variant={getStatusVariant(accountStatus)}
          label={t(`paymentAccount.accountStatus.${accountStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.accountHolderStatus'),
      accessorKey: 'accountHolderStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { accountHolderStatus } } }) => (
        <Tag
          size="small"
          variant={getStatusVariant(accountHolderStatus)}
          label={t(`paymentAccount.accountStatus.${accountHolderStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.isLocked'),
      accessorKey: 'isLocked',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => (original?.isLocked
        ? <Tag size="small" variant="error" label={t('paymentAccount.table.columns.isLocked')} />
        : null),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 10,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-payment-account-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-payment-account-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('paymentAccount.options.goToDetails'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);

                    if (row?.original?.isCompany) {
                      navigate(`/company/${row?.original?.userUid}#payment-account`);
                    } else {
                      navigate(`/user/${row?.original?.userUid}#payment-account`);
                    }
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'onboardingstatus',
      type: 'checkbox',
      title: t('paymentAccount.table.columns.onboardingstatus'),
      options: [
        {
          value: 'pending',
          label: t('profile.profileState.pending'),
        },
        {
          value: 'profile_done',
          label: t('profile.profileState.profile_done'),
        },
        {
          value: 'enrolment_requested,enrolment_pending,enrolment_challenged,enrolment_denied',
          label: t('profile.profileState.enrolment_requested'),
        },
        {
          value: 'enrolment_bypassed,completed',
          label: t('profile.profileState.completed'),
        },
        {
          value: 'no_profile',
          label: t('profile.profileState.no_profile'),
        },
      ],
    },
  ], []);

  return (
    <div
      onClick={() => setTooltipOpen(null)}
      role="presentation"
      onKeyDown={null}
    >
      <div className={styles.heading}>
        <h1>{t('paymentAccount.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('paymentAccount.search')}
        getData={getPaymentAccounts}
        queryKey="payment-account-list"
        allFiltersOptions={allFiltersOptions}
      />
    </div>
  );
}

export default PaymentAccountList;
