/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import {
  Picto, Tag, Button, utils,
} from 'ui-library-unlocker';

// components
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';
import ExportModal from '../../components/molecules/ExportModal/ExportModal';
import DataTable from '../../components/organisms/DataTable/DataTable';

// utils
import { showModal, hideModal } from '../../utils/modal';
import { useAppContext } from '../../store/context';

// Services
import { deleteCompany, getCompanies } from '../../services/company';
import { getCompaniesFromAdmin } from '../../services/admin';

import styles from './CompanyList.module.scss';
import useRoles from '../../hooks/useRoles';

const DELETE_CONFIRM_MODAL_ID = 'company-delete-confirm';
const EXPORT_MODAL_ID = 'company-export-modal';

function CompanyListView() {
  const { t } = useTranslation();
  const { dispatch } = useAppContext();
  const { isUserAdmin } = useRoles();
  const {
    exportOwner,
  } = useFlags();
  const navigate = useNavigate();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [companytoDelete, setCompanyToDelete] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    document.title = t('company.browserTitle');
  }, [t]);

  const formatData = useCallback((data) => {
    if (!data) return data;
    const companies = [...data];
    return companies.sort((a, b) => {
      if (a.isRealEstateAgency && !b.isRealEstateAgency) return -1;
      if (!a.isRealEstateAgency && b.isRealEstateAgency) return 1;
      return 0;
    });
  }, []);

  const deleteCompanyMutation = useMutation({
    mutationFn: () => deleteCompany(companytoDelete?.uid),
    onSuccess: () => {
      utils.toast.success(t('company.crud.deleteSuccess'));
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'company-list' });
      if (companytoDelete?.isRealEstateAgency) {
        localStorage.setItem('hasRealEstateAgency', false);
        dispatch({
          type: 'SET_HAS_REAL_ESTATE_AGENCY',
          payload: false,
        });
      }
      cancelDelete();
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 403: {
            utils.toast.error(t('company.crud.formErrors.PERSON_COMPANY_DELETION_FORBIDDEN'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
      cancelDelete();
    },
  });

  const statusVariant = useMemo(() => (status) => {
    if (!status) return null;
    switch (status) {
      case 'pending':
      case 'enrolment_requested':
      case 'enrolment_pending':
      case 'enrolment_challenged':
      case 'enrolment_denied':
        return 'tertiary';

      case 'profile_done':
        return 'primary';

      case 'blocked':
        return 'error';

      case 'enrolment_bypassed':
      case 'completed':
        return 'success';

      default:
        return 'tertiary';
    }
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('company.table.columns.unlockerID'),
      accessorKey: 'unlockerId',
      size: 200,
    },
    {
      header: t('company.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('company.table.columns.legalName'),
      accessorKey: 'legalName',
      size: 200,
    },
    {
      header: t('company.table.columns.beneficiaries'),
      accessorKey: 'beneficiaries',
      size: 200,
      enableSorting: false,
    },
    {
      header: t('company.table.columns.companyType.title'),
      accessorKey: 'isRealEstateAgency',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { isRealEstateAgency } } }) => (
        <div>
          <Tag
            label={t(`company.table.columns.companyType.${isRealEstateAgency ? 'realEstate' : 'normal'}`)}
            size="medium"
            variant={isRealEstateAgency ? 'secondary' : 'secondary-outline'}
          />
        </div>
      ),
    },
    {
      header: t('company.table.columns.onboardingStatus'),
      accessorKey: 'onboardingStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { onboardingStatus } } }) => (
        <div>
          <Tag
            label={t(`global.companyStatus.${onboardingStatus}`)}
            size="medium"
            variant={statusVariant(onboardingStatus)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row }) => {
        const options = [
          {
            id: 'edit',
            label: t('global.listOptions.edit'),
            icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              navigate(`/company/${row?.original?.uid}`);
            },
          },
          row?.original?.isRealEstateAgency || !exportOwner ? null : {
            id: 'export',
            label: t('company.table.listOptions.export'),
            icon: <Picto icon="export-link" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="export-link" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              setSelectedCompany(row?.original);
              showModal(EXPORT_MODAL_ID);
            },
          },
          {
            id: 'delete',
            label: t('global.listOptions.delete'),
            icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
            onClick: () => {
              setCompanyToDelete({
                uid: row?.original?.uid,
                isRealEstateAgency: row?.original?.isRealEstateAgency,
              });
              showModal(DELETE_CONFIRM_MODAL_ID);
            },
          },
        ];
        return options?.filter((elem) => elem != null).length === 0 ? null : (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-company-${row?.original?.uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === row?.original?.uid}
              anchorId={`more-option-company-${row?.original?.uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={options}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, exportOwner]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'onboardingStatus',
      type: 'checkbox',
      title: t('company.table.columns.onboardingStatus'),
      options: [
        {
          value: 'profile_done',
          label: t('global.companyStatus.profile_done'),
        },
        {
          value: 'enrolment_pending,enrolment_requested,enrolment_challenged,enrolment_denied',
          label: t('global.companyStatus.enrolment_pending'),
        },
        {
          value: 'completed,enrolment_bypassed',
          label: t('global.companyStatus.completed'),
        },
        {
          value: 'blocked',
          label: t('global.companyStatus.blocked'),
        },
      ],
    },
  ], []);

  const entityToExport = useMemo(() => {
    if (selectedCompany) {
      return {
        uid: selectedCompany.uid,
        name: selectedCompany.legalName,
        type: 'company',
      };
    }
    return null;
  }, [selectedCompany]);

  const cancelDelete = useCallback(() => {
    setCompanyToDelete(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleExportClose = useCallback(() => {
    setSelectedCompany(null);
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <div className={styles.heading}>
        <h1>{t('company.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('company.search')}
        getData={isUserAdmin ? getCompaniesFromAdmin : getCompanies}
        queryKey="company-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={(
          <Link to="/company/add">
            <Button
              variant="primary"
              size="large"
              icon="plus"
            >
              {t('company.add')}
            </Button>
          </Link>
        )}
        formatData={formatData}
      />
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={deleteCompanyMutation.mutate}
        onCancel={cancelDelete}
      />
      <ExportModal
        idModal={EXPORT_MODAL_ID}
        entity={entityToExport}
        onClose={handleExportClose}
      />
    </div>
  );
}

export default CompanyListView;
