/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {
  Button,
  utils,
  Message,
  Picto,
  Tag,
  Table,
  UnlockerLoader,
} from 'ui-library-unlocker';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { useAppContext } from '../../../store/context';

// Components
import Modal from '../../../components/molecules/Modal/Modal';
import ConfirmationModal from '../../../components/organisms/ConfirmationModal/ConfirmationModal';
import AddCompanyDocModal from './AddCompanyDocModal/AddCompanyDocModal';

// Hooks
import useRoles from '../../../hooks/useRoles';

// Services
import { deleteCompanyDocument, requestCompanyEnrolment } from '../../../services/company';
import { getDocumentList } from '../../../services/document';
import { getCompanyDocumentsFromAdmin } from '../../../services/admin';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';
import { DOC_TYPES } from '../../../utils/constants';

import { showModal, hideModal } from '../../../utils/modal';

// import { legalCategories } from '../../../utils/forms/companyDetailsSchema';

import styles from './CompanyDocuments.module.scss';

const ADD_DOCUMENT_MODAL_ID = 'add-document-modal';
const ACCEPTED_DOCUMENTS_MODAL_ID = 'accepted-documents-modal';
const VALIDATION_REQUEST_MODAL_ID = 'send-validation-request-confirm-modal';
const DELETE_CONFIRM_MODAL_ID = 'company-delete-confirm';

function CompanyDocuments({ companyData, refetchCompany }) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();
  const navigate = useNavigate();
  const { isUserAdmin } = useRoles();

  const [missingDocuments, setMissingDocuments] = useState(null);
  const [docSelected, setDocSelected] = useState(null);
  const [fileType, setFileType] = useState(null);

  const match = useMatch('/company/:id');

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['company-documents', user, isUserAdmin],
    queryFn: async () => {
      if (isUserAdmin) {
        const response = await getCompanyDocumentsFromAdmin(match?.params?.id);
        return { data: { collection: response?.data } };
      }
      return getDocumentList({
        filters: {
          companyUID: match?.params?.id,
        },
      });
    },
    enabled: !!user,
    keepPreviousData: true,
  });

  const documentDeleteMutation = useMutation({
    mutationFn: deleteCompanyDocument,
    onSuccess: () => {
      utils.toast.success(t('global.documents.delete.success'));
      refetchDocumentList();
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const statusVariant = useMemo(() => (s) => {
    switch (s) {
      case 'validated':
        return 'secondary-outline';
      case 'pending':
        return 'secondary';
      case 'rejected':
        return 'error';
      default:
        return 'error';
    }
  }, []);

  const handleDelete = useCallback((uid) => {
    setDocSelected(uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const cancel = useCallback(() => {
    setDocSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  // KBIS
  const kbis = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.kbis);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.kbis,
      add: true,
    }];
  }, [documentListData]);

  const kbisColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.kbis.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>{t('company.crud.docs.kbis.placeholder')}</div>
      ),
    },
    {
      header: t('company.crud.docs.kbis.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.kbis.columns.date'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { date } } }) => (
        <div>
          {date ? format(new Date(date), 'dd MM yyyy', { locale: fr }) : null}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.kbis.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.kbis);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // RNA / JO / ARUP for associations
  const decree = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.decree);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.decree,
      add: true,
    }];
  }, [documentListData]);

  const decreeColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.decree.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>{t('company.crud.docs.decree.placeholder')}</div>
      ),
    },
    {
      header: t('company.crud.docs.decree.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.decree.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.decree);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Justificatif de pouvoir
  const powerProof = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.powerProof);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.powerProof,
      add: true,
    }];
  }, [documentListData]);

  const powerProofColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.powerProof.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>{t('company.crud.docs.powerProof.placeholder')}</div>
      ),
    },
    {
      header: t('company.crud.docs.powerProof.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.powerProof.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.powerProof);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Justificatifs d'identite
  const identityProof = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.identityProof);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.identityProof,
      add: true,
    }];
  }, [documentListData]);

  const identityProofColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.identityProof.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>
          {t('company.crud.docs.identityProof.placeholder')}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.identityProof.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.identityProof.columns.expireDate'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { date } } }) => (
        <div>
          {date ? format(new Date(date), 'dd MM yyyy', { locale: fr }) : null}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.identityProof.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.identityProof);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Liste des beneficiaires effectfs +25%
  const beneficiariesList = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.beneficiariesList);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.beneficiariesList,
      add: true,
    }];
  }, [documentListData]);

  const beneficiariesListColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.beneficiariesList.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>
          {t('company.crud.docs.beneficiariesList.placeholder')}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.beneficiariesList.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.beneficiariesList.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.beneficiariesList);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Justificatifs beneficiaires effectfs +25%
  const benefIdentities = useMemo(() => {
    const items = [];
    const item1 = documentListData?.data?.collection.find((doc) => doc?.type === DOC_TYPES.benefIdentities);
    const item2 = documentListData?.data?.collection.find((doc) => doc?.type === DOC_TYPES.benefIdentities2);
    const item3 = documentListData?.data?.collection.find((doc) => doc?.type === DOC_TYPES.benefIdentities3);

    if (item1) items.push(item1);
    if (item2) items.push(item2);
    if (item3) items.push(item3);
    if (items.length < 3) {
      let missingType;
      if (!item1) missingType = DOC_TYPES.benefIdentities;
      else if (!item2) missingType = DOC_TYPES.benefIdentities2;
      else if (!item3) missingType = DOC_TYPES.benefIdentities3;
      items.push({ type: missingType, add: true });
    }
    return items;
  }, [documentListData]);

  const benefIdentitiesColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.benefIdentities.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>
          {t('company.crud.docs.benefIdentities.placeholder')}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.benefIdentities.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.benefIdentities.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add, type } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(type);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Chiffre d'affaires
  const turnover = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.turnover);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.turnover,
      add: true,
    }];
  }, [documentListData]);

  const turnoverColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.turnover.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>
          {t('company.crud.docs.turnover.placeholder')}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.turnover.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.turnover.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.turnover);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Sirene
  const sirene = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.sirene);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.sirene,
      add: true,
    }];
  }, [documentListData]);

  const sireneColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.sirene.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>{t('company.crud.docs.sirene.placeholder')}</div>
      ),
    },
    {
      header: t('company.crud.docs.sirene.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.sirene.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.sirene);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Justificatif d'adresse
  const addressProof = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.addressProof);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.addressProof,
      add: true,
    }];
  }, [documentListData]);

  const addressProofColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.addressProof.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>
          {t('company.crud.docs.addressProof.placeholder')}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.addressProof.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.addressProof.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.addressProof);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // IBAN
  const iban = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.iban);
    if (target?.length > 0) {
      return target;
    }
    return [{
      type: DOC_TYPES.iban,
      add: true,
    }];
  }, [documentListData]);

  const ibanColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.iban.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>
          {t('company.crud.docs.iban.placeholder')}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.iban.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.iban.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(DOC_TYPES.iban);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // Autres
  const others = useMemo(() => {
    const target = documentListData?.data?.collection.filter((doc) => doc?.type === DOC_TYPES.others);
    if (target?.length >= 10) {
      return target;
    }
    return [
      ...(target || []),
      {
        type: DOC_TYPES.others,
        add: true,
      },
    ];
  }, [documentListData]);

  const othersColumns = useMemo(() => ([
    {
      header: t('company.crud.docs.others.columns.category'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>
          {t('company.crud.docs.others.placeholder')}
        </div>
      ),
    },
    {
      header: t('company.crud.docs.others.columns.fileName'),
      accessorKey: 'name',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    {
      header: t('company.crud.docs.others.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`company.crud.docs.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add, type } } }) => (
        <div>
          {add ? (
            <Button
              icon="plus"
              onClick={() => {
                setFileType(type);
                showModal(ADD_DOCUMENT_MODAL_ID);
              }}
              className={styles.addIcon}
              color="var(--color-secondary)"
              size="small"
            />
          ) : (
            <Picto
              icon="trash"
              onClick={() => handleDelete(uid)}
              className={styles.deleteIcon}
              color="var(--color-secondary)"
              width={20}
            />
          )}
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  // TODO: post v1, include text for associations
  const getAcceptedDocumentsContent = useMemo(() => (
    <div className={styles.acceptedDocuments}>
      <h3 className="m-t-50 m-b-10">{t('profile.documents.acceptedDocuments.physical.identityIdTitle')}</h3>
      <ul>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.identityId1')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.identityId2')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.identityId3')}</li>
      </ul>

      <h3 className="m-t-30 m-b-10">
        {t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressIdTitle')}
      </h3>
      <ul>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId1')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId2')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId3')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId4')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId5')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId6')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId7')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId8')}</li>
      </ul>
    </div>
  ), [t]);

  const validationBtnLabel = useMemo(() => {
    switch (companyData?.data?.onboardingStatus) {
      case 'profile_done':
        return t('profile.submit');
      case 'enrolment_pending':
      case 'enrolment_requested':
      case 'enrolment_challenged':
      case 'enrolment_denied':
        return t('profile.submitted');
      case 'enrolment_bypassed':
      case 'completed':
        return t('profile.submitValidated');
      default:
        return t('profile.submit');
    }
  }, [companyData, t]);

  const cannotSendValidationRequest = useMemo(() => {
    const { onboardingStatus } = companyData?.data ?? {};
    if (!onboardingStatus) {
      return true;
    }
    if (onboardingStatus !== 'profile_done' && onboardingStatus !== 'enrolment_denied') {
      return true;
    }
    return false;
  }, [companyData]);

  const enrolmentMutation = useMutation({
    mutationFn: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sent_validation_request',
          entityType: 'company',
        },
      });
      return requestCompanyEnrolment(match?.params?.id);
    },
    onSuccess: () => {
      utils.toast.success(t('profile.documents.validationSentSuccess'));
      refetchDocumentList();
      refetchCompany();
      setMissingDocuments(null);
      hideModal(VALIDATION_REQUEST_MODAL_ID);
      navigate('/');
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            switch (err?.response?.data?.message) {
              case 'COMPANY_ENROLMENT_NATURAL_PERSON_DOCUMENTS_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorPersonMissingDocuments'));
                break;
              }
              case 'COMPANY_ENROLMENT_NATURAL_PERSON_PROFILE_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.needProfileDone'));
                break;
              }
              case 'ENROLMENT_COMPANY_DOCUMENTS_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));
                const { errors } = err?.response?.data || {};
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'incomplete_validation_request',
                    entityType: 'company',
                  },
                });
                if (errors) {
                  const missing = Object.values(errors);
                  setMissingDocuments(missing || null);
                }
                break;
              }
              default:
                utils.toast.error(t('global.form.errors.global'));
                break;
            }
            break;
          }
          case 409: {
            utils.toast.error(t('profile.paymentAccount.errorInProgress'));
            break;
          }
          default:
            break;
        }
        hideModal(VALIDATION_REQUEST_MODAL_ID);
      }
    },
  });

  const handleCloseUploadModal = useCallback(() => {
    setFileType(null);
  }, [setFileType]);

  const displayMissingDocuments = useMemo(() => {
    if (!missingDocuments) return null;
    const docTypeEntries = Object.entries(DOC_TYPES);
    return (
      <>
        <p className="p-2-700 m-b-10">
          {t('profile.documents.missingDocuments')}
        </p>
        <ul className={styles.missingList}>
          {missingDocuments.map((doc) => {
            const docKey = docTypeEntries.find(([, value]) => value === doc?.toLowerCase())?.[0];
            return (
              <li key={doc}>{t(`company.crud.docs.${docKey}.title`)}</li>
            );
          })}
        </ul>
      </>
    );
  }, [missingDocuments, t]);

  const isAssociation = useMemo(() => companyData?.data?.legalCategory === '92', [companyData]);

  if (!uiBuilders || documentListFetching) return <UnlockerLoader size={300} />;

  return (
    <div className="m-t-50">
      <h2>{t('company.crud.docs.title')}</h2>

      <div className={styles.docsRequirements}>
        <ul>
          <li>{t('company.crud.docs.requirements.required')}</li>
          <li>{t('company.crud.docs.requirements.check')}</li>
          <li>{t('company.crud.docs.requirements.upload')}</li>
        </ul>
      </div>

      {/* KBIS */}
      <div className={styles.stepTitle}>
        <Tag
          variant="secondary"
          size="small"
          label={t('company.crud.docs.step', { count: 1 })}
        />
        <h3 className="t-h3-700">
          {t(`company.crud.docs.${isAssociation ? 'decree' : 'kbis'}.title`)}
        </h3>
      </div>

      <div className="m-t-20">
        <Table
          fullWidth
          columns={isAssociation ? decreeColumns : kbisColumns}
          data={isAssociation ? decree : kbis}
        />
      </div>

      {/* Justificatif de pouvoir */}
      <h4 className={styles.titleH4}>
        {t(`company.crud.docs.powerProof.${isAssociation ? 'assocTitle' : 'title'}`)}
      </h4>

      <div className="m-t-25">
        <Table
          fullWidth
          columns={powerProofColumns}
          data={powerProof}
        />
      </div>

      {/* Justificatif d'identite */}
      <div className={styles.stepTitle}>
        <Tag
          variant="secondary"
          size="small"
          label={t('company.crud.docs.step', { count: 2 })}
        />
        <h3 className="t-h3-700">{t('company.crud.docs.identityProof.title')}</h3>
      </div>

      <div className="m-t-20">
        <Table
          fullWidth
          columns={identityProofColumns}
          data={identityProof}
        />
      </div>

      {/* Liste des bénéficiaires effectifs +25% */}
      <div className={styles.stepTitle}>
        <Tag
          variant="secondary"
          size="small"
          label={t('company.crud.docs.step', { count: 3 })}
        />
        <h3 className="t-h3-700">{t('company.crud.docs.beneficiariesList.title')}</h3>
      </div>

      <div className="m-t-20">
        <Table
          fullWidth
          columns={beneficiariesListColumns}
          data={beneficiariesList}
        />
      </div>

      {/* Bénéficiaires justificatifs identite */}
      <h4 className={styles.titleH4}>{t('company.crud.docs.benefIdentities.title')}</h4>

      <div className="m-t-25">
        <Table
          fullWidth
          columns={benefIdentitiesColumns}
          data={benefIdentities}
        />
      </div>

      {/* Chiffre d'affaire */}
      <div className={styles.stepTitle}>
        <Tag
          variant="secondary"
          size="small"
          label={t('company.crud.docs.step', { count: 4 })}
        />
        <h3 className="t-h3-700">{t('company.crud.docs.turnover.title')}</h3>
      </div>

      <div className="m-t-20">
        <Table
          fullWidth
          columns={turnoverColumns}
          data={turnover}
        />
      </div>

      {/* Sirene */}
      {isAssociation && (
        <>
          <div className={styles.stepTitle}>
            <Tag
              variant="secondary"
              size="small"
              label={t('company.crud.docs.step', { count: 5 })}
            />
            <h3 className="t-h3-700">{t('company.crud.docs.sirene.title')}</h3>
          </div>

          <div className="m-t-20">
            <Table
              fullWidth
              columns={sireneColumns}
              data={sirene}
            />
          </div>
        </>
      )}

      {/* Justificatif d'adresse */}
      <div className={styles.stepTitle}>
        <Tag
          variant="secondary"
          size="small"
          label={t('company.crud.docs.step', { count: isAssociation ? 6 : 5 })}
        />
        <h3 className="t-h3-700">{t('company.crud.docs.addressProof.title')}</h3>
      </div>

      <div className="m-t-20">
        <Table
          fullWidth
          columns={addressProofColumns}
          data={addressProof}
        />
      </div>

      {/* IBAN */}
      <div className={styles.stepTitle}>
        <Tag
          variant="secondary"
          size="small"
          label={t('company.crud.docs.step', { count: isAssociation ? 7 : 6 })}
        />
        <h3 className="t-h3-700">{t('company.crud.docs.iban.title')}</h3>
      </div>

      <div className="m-t-20">
        <Table
          fullWidth
          columns={ibanColumns}
          data={iban}
        />
      </div>

      {/* Autres */}
      <div className={styles.stepTitle}>
        <Tag
          variant="secondary"
          size="small"
          label={t('company.crud.docs.step', { count: isAssociation ? 8 : 7 })}
        />
        <h3 className="t-h3-700">{t('company.crud.docs.others.title')}</h3>
        <small>{t('global.form.optional')}</small>
      </div>

      <div className="m-t-20">
        <Table
          fullWidth
          columns={othersColumns}
          data={others}
        />
      </div>

      { missingDocuments?.length > 0 && (
        <div className={styles.missingBlock}>
          <Message
            className="m-t-30"
            icon={null}
            variant="alert"
            content={displayMissingDocuments}
          />
        </div>
      )}

      <Button
        className="m-t-40 center-block"
        icon="etat-lieux"
        size="large"
        disabled={cannotSendValidationRequest || documentListData?.data?.collection?.length < 1}
        onClick={() => {
          showModal(VALIDATION_REQUEST_MODAL_ID);
        }}
      >
        {validationBtnLabel}
      </Button>

      <AddCompanyDocModal
        modalId={ADD_DOCUMENT_MODAL_ID}
        companyUID={match?.params?.id}
        refetchDocumentList={refetchDocumentList}
        fileType={fileType}
        onClose={handleCloseUploadModal}
      />

      {/* Send validation request modal */}
      <Modal
        id={VALIDATION_REQUEST_MODAL_ID}
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => {
          hideModal('send-validation-request-confirm-modal');
        }}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation?.isLoading}
          onClick={() => {
            enrolmentMutation.mutate();
          }}
        >
          {t('global.validate')}
        </Button>
      </Modal>

      {/* Accepted Documents Modal */}
      <Modal
        id={ACCEPTED_DOCUMENTS_MODAL_ID}
        title={t('profile.documents.acceptedDocumentsTitle')}
        size="large"
        onClose={() => {
          hideModal(ACCEPTED_DOCUMENTS_MODAL_ID);
        }}
      >
        {getAcceptedDocumentsContent}
      </Modal>

      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={() => documentDeleteMutation.mutate(docSelected)}
        onCancel={cancel}
      />
    </div>
  );
}

CompanyDocuments.propTypes = {
  companyData: PropTypes.shape({
    data: PropTypes.shape({
      legalCategory: PropTypes.string,
      onboardingStatus: PropTypes.string,
    }),
  }).isRequired,
  refetchCompany: PropTypes.func.isRequired,
};

export default CompanyDocuments;
