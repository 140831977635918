/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import {
  TextInput,
  SelectInput,
  Button,
  Message,
  Picto,
  utils,
} from 'ui-library-unlocker';

import { useAppContext } from '../../../store/context';

// Components
import FileInput from '../../../components/molecules/FileInput/FileInput';
import Modal from '../../../components/molecules/Modal/Modal';
import DocumentList from '../../../components/organisms/DocumentList/DocumentList';
import FormInfoRequired from '../../../components/atoms/FormInfoRequired/FormInfoRequired';

// Hooks
import useFileUpload from '../../../hooks/useFileUpload';
import useProfile from '../../../hooks/useProfile';
import useProfileCompletion from '../../../hooks/useProfileCompletion';
import useScroll from '../../../hooks/useScroll';

// Services
import { createUserDocument, getPersonDocuments, deleteUserDocument } from '../../../services/person';
import { requestBankingEnrolment } from '../../../services/profile';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';

import { showModal, hideModal } from '../../../utils/modal';
import { displayError, isFieldValid } from '../../../utils/forms/form';

import { getDocumentSchema, documentInitialValues } from './documentSchema';

import styles from './ProfileDocuments.module.scss';
import { getMe } from '../../../services/identity';

function ProfileDocuments() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders }, dispatch } = useAppContext();
  const {
    isLoadingProfile,
    profile,
  } = useProfile();
  const { isUserCompletionLevel1 } = useProfileCompletion();
  const { scrollContentToTop } = useScroll();
  const navigate = useNavigate();

  const [isHugeDocument, setHugeDocument] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  const addDocumentModalId = 'add-document-modal';
  const acceptedDocumentsModalId = 'accepted-documents-modal';

  const physicalFileTypeExclude = ['company_identity'];

  const { data: meData, refetch: refetchMe, isLoading: meLoading } = useQuery({
    queryKey: ['me-data'],
    queryFn: async () => {
      const meResponse = await getMe();

      dispatch({
        type: 'SET_ME',
        payload: meResponse.data,
      });

      return meResponse;
    },
  });

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['profile-documents', user, page, itemsPerPage],
    queryFn: async () => {
      if (user) {
        return getPersonDocuments(user?.username, {
          page,
          itemsPerPage,
        });
      }
      return [];
    },
    enabled: !!user,
    keepPreviousData: true,
  });

  const documentDeleteMutation = useMutation({
    mutationFn: deleteUserDocument,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('global.documents.delete.success'));
          scrollContentToTop();
          refetchDocumentList();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const [missingDocuments, setMissingDocuments] = useState(null);
  const enrolmentMutation = useMutation({
    mutationFn: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sent_validation_request',
          entityType: 'profile',
        },
      });
      return requestBankingEnrolment();
    },
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('profile.paymentAccount.success'));
          refetchDocumentList();
          refetchMe();
          setMissingDocuments(null);
          hideModal('send-validation-request-confirm-modal');
          navigate('/');
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));

            if (err?.response?.data?.message === 'PERSON_ENROLMENT_DOCUMENT_MISSING') {
              const { errors } = err?.response?.data || {};
              TagManager.dataLayer({
                dataLayer: {
                  event: 'incomplete_validation_request',
                  entityType: 'profile',
                },
              });
              if (errors) {
                const missing = Object.keys(errors).map((key) => errors[key]);
                setMissingDocuments(missing || null);
              }
            }
            break;
          }
          case 409: {
            utils.toast.error(t('profile.paymentAccount.errorInProgress'));
            break;
          }
          default:
            break;
        }
        hideModal('send-validation-request-confirm-modal');
      }
    },
  });

  const getDocumentType = useCallback(() => {
    if (uiBuilders == null || isLoadingProfile) return {};
    return uiBuilders['/person/ui']?.documentTypePPH;
  }, [uiBuilders, isLoadingProfile]);

  const validationSchema = useMemo(() => {
    if (uiBuilders) {
      return getDocumentSchema({ documentType: Object.keys(getDocumentType()) });
    }
    return getDocumentSchema({ documentType: [] });
  }, [uiBuilders, getDocumentType]);

  const documentTypeOptions = useMemo(() => {
    if (uiBuilders) {
      const documentType = getDocumentType();
      return Object.keys(documentType).filter((key) => {
        if (physicalFileTypeExclude.find((type) => type === key) !== undefined) return false;
        return true;
      }).map((key) => ({
        value: key,
        label: documentType[key],
      }));
    }
    return [];
  }, [uiBuilders, getDocumentType, physicalFileTypeExclude]);

  const initialValues = useMemo(() => {
    if (user) {
      return {
        ...documentInitialValues,
        userUid: user?.username,
      };
    }
    return documentInitialValues;
  }, [user]);

  const DOCUMENT_TYPE = 'userDocuments';

  const resetDocumentModal = () => {
    hideModal(addDocumentModalId);
    setFileType(null);
    setFile(null);
    setHugeDocument(false);
    formik.resetForm();
  };

  const createDocumentCb = async (documentUid, fileName) => {
    const createdDocument = await createUserDocument({
      type: fileType,
      name: fileName,
      documentUid,
      personUid: user.username,
    });
    refetchDocumentList();
    resetDocumentModal();
    setMissingDocuments(null);
    return createdDocument;
  };

  const [{ uploadFile, setFileType, setFile }, {
    fileType, file, isUploading,
  }] = useFileUpload(createDocumentCb);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'document_added',
            entityType: 'profile',
            fileType: values?.type,
          },
        });
        uploadFile({
          ...values,
          type: DOCUMENT_TYPE,
        });
      } else {
        utils.toast.info(t('profile.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('profile.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!uiBuilders) return null;
        const documentType = getDocumentType()[original?.type];
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('profile.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 300,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
  ]), [t, uiBuilders, getDocumentType]);

  const fileList = useMemo(() => {
    if (uiBuilders) {
      return Object.keys(getDocumentType()).filter((key) => {
        if (physicalFileTypeExclude.find((type) => type === key) !== undefined) return false;
        return true;
      });
    }
    return [];
  }, [uiBuilders, physicalFileTypeExclude, getDocumentType]);

  const cannotSendValidationRequest = (meData?.data?.onboardingStatus !== 'profile_done'
  && meData?.data?.onboardingStatus !== 'enrolment_denied')
  || (meData?.data?.onboardingStatus === 'enrolment_requested'
  || meData?.data?.onboardingStatus === 'enrolment_pending');

  const category = useMemo(() => [
    'profile.documents.physical.documents.iban',
    'profile.documents.physical.documents.personId',
    'profile.documents.physical.documents.proof_of_residence',
  ], []);

  if (meLoading) {
    return (
      <Picto width={20} icon="loading" />
    );
  }

  return (
    <div className="m-t-50">
      { isUserCompletionLevel1 && (
        <Message
          content={t('profile.documents.kindlyUploadDocuments')}
          variant="info"
          className="m-b-20"
        />
      )}
      <h2>{t('profile.documents.title')}</h2>
      <div className="m-t-20 m-b-20">
        <Message
          className={utils.cn([
            'm-t-30 m-b-30 p-l-20 p-t-20 p-r-20 p-b-20',
            styles.contextMessage,
          ])}
          variant="info"
          icon={null}
          content={(
            <div>
              <p className="p-1-700">
                {t('profile.documents.requiredDocumentsPhysical')}
              </p>
              <ul className={styles.contextMessageList}>
                {category.map((item) => (
                  <li key={item}>
                    {t(item)}
                  </li>
                ))}
              </ul>
              <div
                tabIndex={0}
                role="button"
                onKeyDown={null}
                onClick={() => {
                  showModal(acceptedDocumentsModalId);
                }}
                className={utils.cn(['m-t-30 p-2-700', styles.seeDocuments])}
              >
                {t('profile.documents.seeAcceptedDocuments')}
                {' '}
                <Picto width={15} icon="info-circle" />
              </div>
            </div>
          )}
        />
      </div>

      <div className="m-t-10 m-b-60">
        <FormInfoRequired content={t('profile.documents.info')} />
      </div>
      <DocumentList
        className="m-t-25"
        fileList={fileList}
        columns={columns}
        documentListData={documentListData}
        addButton={{
          enabled: true,
          label: t('profile.documents.addDocument'),
          icon: 'plus',
        }}
        onAddBtnClick={() => {
          showModal(addDocumentModalId);
        }}
        onFileListElementAddClick={(element) => {
          const { type: documentType } = element;

          setFileType(documentType);
          formik.setFieldValue('type', documentType);
          showModal(addDocumentModalId);
        }}
        onFileListElementDelete={(element) => {
          documentDeleteMutation.mutate(element?.uid);
        }}
        pagination={{
          page,
          itemsPerPage,
          setPage,
          setItemsPerPage,
        }}
        isDocumentListFetching={documentListFetching}
      />
      { missingDocuments?.length > 0 && (
        <div className={styles.missingBlock}>
          <Message
            className="m-t-30"
            icon={null}
            variant="alert"
            content={(
              <>
                <p className="p-2-700 m-b-10">
                  {t('profile.documents.missingDocuments')}
                </p>
                <ul className={styles.missingList}>
                  {missingDocuments.map((item) => (
                    <li key={item}>
                      {getDocumentType()[item.toLowerCase()]}
                    </li>
                  ))}
                </ul>
              </>
        )}
          />
        </div>
      )}
      {profile?.rentDistribution
      && !['only_with_companies_account', 'real_estate_agency_with_no_properties'].includes(profile.rentDistribution)
      && (
        <Button
          className="m-t-40 center-block"
          icon="etat-lieux"
          size="large"
          disabled={cannotSendValidationRequest || documentListData?.data?.collection?.length < 1}
          onClick={() => {
            showModal('send-validation-request-confirm-modal');
          }}
        >
          {meData?.data?.onboardingStatus === 'profile_done' && t('profile.submit')}
          {meData?.data?.onboardingStatus === 'enrolment_pending' && t('profile.submitted')}
          {meData?.data?.onboardingStatus === 'enrolment_requested' && t('profile.submitted')}
          {meData?.data?.onboardingStatus === 'enrolment_challenged' && t('profile.submit')}
          {meData?.data?.onboardingStatus === 'enrolment_denied' && t('profile.submitted')}
          {meData?.data?.onboardingStatus === 'enrolment_bypassed' && t('profile.submitValidated')}
          {meData?.data?.onboardingStatus === 'completed' && t('profile.submitValidated')}
        </Button>
      )}
      <Modal
        className={styles.modal}
        id={addDocumentModalId}
        title={isHugeDocument
          ? t('profile.documents.addDocumentForm.errors.size.title')
          : t('profile.documents.addDocumentForm.title')}
        size="large"
        onClose={() => {
          hideModal(addDocumentModalId);
        }}
      >
        {!isHugeDocument ? (
          <form onSubmit={formik.handleSubmit} className={styles.addForm}>
            <SelectInput
              isSearchable={false}
              className="m-t-25"
              id="type"
              name="type"
              label={t('profile.documents.addDocumentForm.fields.type')}
              options={documentTypeOptions}
              onChange={(value) => {
                setFileType(value.value);
                formik.setFieldValue('type', value.value);
              }}
              onBlur={formik.handleBlur}
              error={displayError(t, formik, 'type')}
              valid={isFieldValid(formik, 'type', null, initialValues?.type)}
              value={documentTypeOptions.find((type) => type.value === formik.values.type) || null}
            />
            <TextInput
              type="text"
              id="customName"
              name="customName"
              className="m-t-25"
              label={t('profile.documents.addDocumentForm.fields.name')}
              error={displayError(t, formik, 'customName')}
              valid={isFieldValid(formik, 'customName', null, initialValues?.customName)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customName}
            />
            <FileInput
              className="m-t-25"
              id="profile-file"
              name="profile-file"
              label={t('profile.documents.addDocumentForm.fields.file')}
              help={t('profile.documents.addDocumentForm.fileHelp')}
              accept="image/jpg,image/png,image/jpeg,application/pdf"
              value={file}
              onChange={(e) => {
                const f = e?.target?.files[0];

                if (f?.size > 10000000) {
                  setHugeDocument(true);
                }

                if (f) {
                  const setFileFields = (image = null) => {
                    const dim = !image
                      ? {}
                      : {
                        width: image.naturalWidth,
                        height: image.naturalHeight,
                      };
                    formik.setFieldValue('filename', f?.name);
                    formik.setFieldValue('metadata', {
                      documentSize: f?.size,
                      extension: f?.type?.split('/')[1],
                      ...dim,
                    });
                    setFile(f);

                    if (image) image.removeEventListener('load', setFileFields);
                  };

                  if (f?.type === 'application/pdf') {
                    // PDF
                    const reader = new FileReader();
                    reader.onload = () => setFileFields(null);
                    reader.readAsText(f);
                  } else {
                    // IMAGE
                    const image = new Image();
                    image.src = URL.createObjectURL(f);
                    image.addEventListener('load', () => setFileFields(image));
                  }
                } else {
                  setFile(null);
                  formik.setFieldValue('filename', '');
                  formik.setFieldValue('metadata', null);
                }
              }}
            />
            <div className={styles.submitBtn}>
              <Button
                type="submit"
                loading={isUploading}
                className="m-t-25"
                label={t('profile.documents.addDocumentForm.submit')}
              />
            </div>
          </form>
        ) : (
          <div className={styles.hugeDocument}>
            <p className={styles.errorModalSubtitle}>{t('profile.documents.addDocumentForm.errors.size.message')}</p>
            <div className={styles.submitBtn}>
              <Button
                className="m-t-25"
                label={t('profile.documents.addDocumentForm.errors.size.action')}
                onClick={() => {
                  formik.resetForm();
                  setFileType(null);
                  setFile(null);
                  setHugeDocument(false);
                }}
              />
            </div>
          </div>
        ) }
      </Modal>
      <Modal
        id="send-validation-request-confirm-modal"
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => {
          hideModal('send-validation-request-confirm-modal');
        }}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation?.isLoading}
          onClick={() => {
            enrolmentMutation.mutate();
          }}
        >
          {t('global.validate')}
        </Button>
      </Modal>

      {/* Accepted Documents Modal */}
      <Modal
        id={acceptedDocumentsModalId}
        title={t('profile.documents.acceptedDocumentsTitle')}
        size="large"
        onClose={() => {
          hideModal(acceptedDocumentsModalId);
        }}
      >
        <div className={styles.acceptedDocuments}>
          <h3 className="m-t-50 m-b-10">{t('profile.documents.acceptedDocuments.physical.identityIdTitle')}</h3>
          <ul>
            <li>{t('profile.documents.acceptedDocuments.physical.identityId1')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.identityId2')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.identityId3')}</li>
          </ul>

          <h3 className="m-t-30 m-b-10">{t('profile.documents.acceptedDocuments.physical.addressIdTitle')}</h3>
          <ul>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId1')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId2')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId3')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId4')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId5')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId6')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId7')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId8')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId9')}</li>
            <li>{t('profile.documents.acceptedDocuments.physical.addressId10')}</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

ProfileDocuments.propTypes = {
};

export default ProfileDocuments;
