/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useLocation } from 'react-router-dom';
import { Picto, Tag, utils } from 'ui-library-unlocker';

import PersonalInfoForm from '../../components/organisms/forms/PersonalInfoForm/PersonalInfoForm';
import ProfileDocuments from './ProfileDocuments/ProfileDocuments';

// Services
import { getUserProfile, patchUserProfile } from '../../services/profile';
import { getMe } from '../../services/identity';
import { getPersonUI } from '../../services/uiBuilder';

// Hooks
import { useAppContext } from '../../store/context';
import useRoles from '../../hooks/useRoles';

import styles from './Profile.module.scss';

function ProfileView() {
  const { t } = useTranslation();
  const location = useLocation();
  const { context: { me, uiBuilders }, dispatch } = useAppContext();
  const { isUserAdmin } = useRoles();
  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });

  useEffect(() => {
    switch (location.hash) {
      case '#documents':
        document.title = t('profile.documents.browserTitle');
        break;
      default:
        document.title = t('profile.personalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const {
    data: meData,
    refetch: meRefetch,
  } = useQuery({
    queryKey: ['me-data'],
    queryFn: async () => {
      const meResponse = await getMe();

      dispatch({
        type: 'SET_ME',
        payload: meResponse.data,
      });

      return meResponse;
    },
  });

  const profileQuery = useQuery({
    queryKey: ['user-profile', me],
    queryFn: async () => {
      const userProfile = await getUserProfile();
      return {
        ...userProfile,
        data: {
          ...userProfile.data,
          email: meData?.data?.email,
          onboardingStatus: meData?.data?.onboardingStatus,
          unlockerId: meData?.data?.unlockerId,
        },
      };
    },
    enabled: !!me,
  });

  const isProfilePristine = useMemo(() => {
    if (meData?.data?.onboardingStatus === 'pending') return true;
    return false;
  }, [meData?.data]);

  const userMutation = useMutation({
    mutationFn: patchUserProfile,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('owner.tabs.generalInfo.formSuccess'));
          setStatusMessage({ displayed: true, value: t('owner.tabs.generalInfo.formSuccess') });
          break;
        case 204:
          meRefetch();
          profileQuery.refetch().then(async () => {
            setStatusMessage({ displayed: true, value: t('profile.personalInfo.form.success') });
            utils.toast.success(t('profile.personalInfo.form.success'));

            // Refetch ui builder for person
            const personUi = await getPersonUI();
            const newState = { ...uiBuilders, [personUi?.config?.url]: personUi?.data };
            dispatch({ type: 'SET_UI_BUILDERS', payload: newState });
          });
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response?.status === 400) {
        utils.toast.error(t('global.form.errors.generic'));
      }
    },
  });

  const tabs = useMemo(() => [
    {
      label: t('profile.personalInfo.tabTitle'),
      title: t('profile.personalInfo.title'),
      hash: '',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      show: true,
      isCompleted: profileQuery.data !== undefined,
      component: (
        <PersonalInfoForm
          isProfilePristine={isProfilePristine}
          userQuery={profileQuery}
          userMutation={userMutation}
          statusMessage={statusMessage}
        />
      ),
    },
    {
      label: t('profile.documents.tabTitle'),
      title: t('profile.documents.title'),
      hash: 'documents',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      show: !isUserAdmin,
      disabled: false,
      component: (
        <ProfileDocuments />),
    },
  ], [
    t,
    location,
    profileQuery,
    isProfilePristine,
  ]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    const tab = tabs.find((cur) => cur.hash === hash && cur.show);
    return tab || tabs[0];
  }, [tabs, location]);

  const formStatus = useMemo(() => {
    if (!meData?.data) return null;
    switch (meData?.data?.onboardingStatus) {
      case 'pending':
        return {
          variant: 'tertiary',
        };
      case 'profile_done':
        return {
          variant: 'primary',
        };
      case 'enrolment_requested':
        return {
          variant: 'tertiary',
        };
      case 'enrolment_pending':
        return {
          variant: 'tertiary',
        };
      case 'enrolment_challenged':
        return {
          variant: 'tertiary',
        };
      case 'enrolment_denied':
        return {
          variant: 'error',
        };
      case 'enrolment_bypassed':
      case 'completed':
        return {
          variant: 'success',
        };
      default:
        return {
          variant: 'tertiary',
        };
    }
  }, [meData?.data]);

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.header}>
          <h1>
            {t('profile.title')}
            {' '}
          </h1>
          {!isUserAdmin && formStatus && (
          <Tag
            label={t(`profile.profileState.${meData?.data?.onboardingStatus}`)}
            size="medium"
            variant={formStatus.variant}
            className="m-l-10"
          />
          )}
        </div>
        <ul className={styles.tabList}>
          {tabs.map((tab) => {
            if (!tab.show) return null;
            const isActive = tab.hash === currentTab.hash;

            return (
              <li
                className={utils.cn([
                  styles.tabItem,
                  isActive ? styles.active : null,
                  tab.disabled ? styles.disabled : null,
                ])}
                key={tab.hash}
              >
                <Link className={styles.tabLink} to={`#${tab.hash}`}>
                  <span>{tab.label}</span>
                  {/* TODO: fix css */}
                  {/* {tab.isCompleted
                  && (
                  <Picto
                    width={10}
                    height={10}
                    color="var(--color-secondary)"
                    icon="tick-circle"
                  />
                  )} */}
                </Link>
                {isActive && tab.activePicto}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

export default ProfileView;
