import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Picto, utils, WithHeader } from 'ui-library-unlocker';

import styles from './Maintenance.module.scss';

function Maintenance() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    document.title = t('maintenance.browserTitle');
  }, [t]);

  return (
    <WithHeader
      logoLink="/register"
      isContained={false}
      className={utils.cn([
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >
      <div className={styles.maintenance}>
        <div
          className={styles.cover}
        >
          <Picto
            className={utils.cn([styles.decoration, styles.topDeco])}
            icon="point-moyen-900"
          />
          <Picto
            className={utils.cn([styles.decoration, styles.bottomDeco])}
            icon="gribouillon-6"
          />
          <img
            className={styles.img}
            src="/images/500.png"
            alt="Personne assise dans un salon avec un ordinateur sur les genoux"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>{t('maintenance.title')}</h1>
            <Picto
              className={styles.underline}
              icon="trace-3511"
              color="var(--color-tertiary)"
              width={380}
            />
          </div>
          <p>{t('maintenance.explanation')}</p>
        </div>
      </div>
      <footer className={styles.footer}>
        {!isMobile && (
        <>
          <a href="/register" className={styles.footerLogo}>
            <Picto icon="logo-horizontal" width={160} color="var(--color-black)" />
          </a>

          <div className={styles.socialNetworks}>
            <a href="https://www.facebook.com/profile.php?id=100085865975036" target="_blank" rel="noopener noreferrer">
              <Picto icon="facebook-frame" width={20} color="var(--color-black)" />
            </a>
            <a href="https://fr.linkedin.com/company/unlockerapp" target="_blank" rel="noopener noreferrer">
              <Picto icon="linkedin-frame" width={24} color="var(--color-black)" />
            </a>
          </div>
        </>
        )}

        <div className={styles.userCtas}>
          <a
            className={styles.storePill}
            href="https://apps.apple.com/us/app/unlocker/id1667221898"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/cta-app-store.png"
              alt="Download on the App Store"
            />
          </a>
          <a
            className={styles.storePill}
            href="https://play.google.com/store/apps/details?id=com.unlockerapp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="m-l-20"
              src="/images/cta-google-play.png"
              alt="Get it on Google Play"
            />
          </a>
        </div>
      </footer>
    </WithHeader>
  );
}

export default Maintenance;
