import api from './_client';

export const getPaymentAccounts = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.id?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/payment/account', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getPaymentAccount = (uid) => api.get(`/payment/account/${uid}`);

export const submitPaymentAccountEnrolment = (uid) => api.post(`/payment/admin/enrolment/submit/${uid}`);
