/* eslint-disable max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'ui-library-unlocker';

import styles from './FiltersSelected.module.scss';

function FiltersSelected({
  filtersSelected,
  filtersList,
  onRemove,
  onRemoveAll,
  tagVariant,
  tagSize,
}) {
  const { t } = useTranslation();
  const list = useMemo(
    () => Object.keys(filtersSelected)
      .reduce(
        (prev, cur) => {
          if (cur !== 'city') return [...prev, ...filtersSelected[cur]];
          return prev;
        },
        [],
      ),
    [filtersSelected],
  );

  const getFilterValueOnRemove = (filterValue) => {
    Object.keys(filtersSelected).forEach((filterType) => {
      const f = filtersSelected[filterType];

      if (f?.find((value) => value === filterValue) !== undefined) {
        onRemove(filterType, f.filter((value) => value !== filterValue));
      }
    });
  };

  return list?.length > 0 && (
    <div className={styles.filtersSelected}>
      {list.map((filter) => (
        <Tag
          key={filter}
          label={filtersList?.find((filterEl) => filterEl?.value === filter)?.label}
          variant={tagVariant}
          onClose={() => getFilterValueOnRemove(filter)}
          showCloseIcon
          size={tagSize}
        />
      ))}
      {list?.length > 0 ? (
        <button type="button" className={styles.filter} onClick={onRemoveAll}>
          <span className={tagSize === 'large' ? 'p-1-700' : 'p-2-700'}>
            {t('global.filters.eraseAll')}
          </span>
        </button>
      ) : null}
    </div>
  );
}

FiltersSelected.propTypes = {
  filtersSelected: PropTypes.shape().isRequired,
  filtersList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  tagVariant: PropTypes.string,
  tagSize: PropTypes.string,
};

FiltersSelected.defaultProps = {
  onRemove: () => {},
  onRemoveAll: () => {},
  tagVariant: 'transparent',
  tagSize: 'large',
};

export default FiltersSelected;
