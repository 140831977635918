import axios from 'axios';
import api from './_client';

// eslint-disable-next-line no-unused-vars
function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsBinaryString(file);
  });
}

export const getDocumentList = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[company][--eq--]': filters.companyUID,
  };

  return api.get('/person/documents', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getDocumentDownloadUrl = (documentUid) => api.get(`/document/download/${documentUid}/get-presigned-url`);

export const getDocumentUploadUrl = (data) => api.post('/document/upload/get-presigned-url', data);

export const uploadToBucket = (presignedUrl, file) => (
  axios.put(presignedUrl, file, {
    headers: {
      'Content-Type': file?.type,
    },
  }));
