export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN', // admin
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN', // super admin
  ROLE_LESSOR: 'ROLE_LESSOR', // LESSOR
  ROLE_USER: 'ROLE_USER', // tenant
};

export const COMPLETION_STATUS = {
  PENDING: 'pending',
  PROFILE_DONE: 'profile_done',
  ENROLMENT_REQUESTED: 'enrolment_requested',
  ENROLMENT_PENDING: 'enrolment_pending',
  ENROLMENT_CHALLENGED: 'enrolment_challenged',
  ENROLMENT_DENIED: 'enrolment_denied',
  ENROLMENT_BYPASSED: 'enrolment_bypassed',
  COMPLETED: 'completed',
  BLOCKED: 'blocked',
};

export const DOC_TYPES = {
  kbis: 'incorporation_certificate',
  decree: 'decree_or_official_extract',
  powerProof: 'additional_measures',
  identityProof: 'photo',
  beneficiariesList: 'lbe_complement_1',
  benefIdentities: 'other2_documents_legal_entity',
  benefIdentities2: 'other3_documents_legal_entity',
  benefIdentities3: 'lbe_complement_2',
  turnover: 'other_documents_legal_identity',
  sirene: 'proof_of_status',
  addressProof: 'other_proof_of_residence',
  iban: 'proof_of_bank_domiciliation_legal_entity',
  others: 'CUSTOM_OTHER_DOCUMENTS',
};

export const PAYMENT_AND_ACCOUNT_STATUS_LIST = {
  SCHEDULED: 'scheduled', // payment
  SCHEDULED_MANUAL: 'scheduled_manual', // payment
  NOTIFIED: 'notified', // payment
  PENDING: 'pending', // payment
  EXPORTED: 'exported', // payment
  READY_TO_CHECK: 'ready_to_check', // account
  IN_WIRING: 'in_wiring', // account
  SUCCESS: 'success', // payment
  RECEIVED: 'received', // account
  SENT: 'sent', // account
  READY_TO_WIRE: 'ready_to_wire', // account
  FAILED: 'failed', // payment
  CANCELED: 'canceled', // payment
  NOT_DOABLE: 'not_doable', // account
  SCHEDULING_ERROR: 'scheduling_error', // payment
};
