/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../../utils/cn';

import styles from './ProgressBar.module.scss';

// onClose display a close icon if specified
const ProgressBar = ({
  value,
  total,
  className,
  size,
  hidden,
}) => {
  const calculatePercent = () => (
    total === 0 ? 100 : Math.floor((Math.max(value, 0) / total) * 100)
  );

  return (
    <div
      className={cn([
        className,
        styles.progressBar,
        hidden ? styles.hidden : null,
      ])}
    >
      <div
        className={cn([
          styles.bar,
          styles[size],
        ])}
      >
        <div
          className={styles.progress}
          style={{
            width: `${Math.min(calculatePercent(), 100)}%`,
          }}
        />
      </div>

      <span className={styles.label}>
        {calculatePercent()}
        %
      </span>
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  hidden: PropTypes.bool,
};

ProgressBar.defaultProps = {
  total: 100,
  className: null,
  size: 'medium',
  hidden: false,
};

export default ProgressBar;
