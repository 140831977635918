/* eslint-disable max-len */
import api from './_client';

export const createLease = (data) => api.post('/lease/leases', data);

export const getTenantLeases = (tenantUid, { page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    q: filters.search?.[0],
  };

  return api.get(`/lease/tenants/${tenantUid}/leases`, {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getLease = (leaseUid) => api.get(`/lease/leases/${leaseUid}`, {});

export const getLeases = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--eq--]': filters.statusEq,
    'filters[status][--in--]': filters.status?.join?.(','),
    q: filters.search?.[0],
  };

  return api.get('/lease/leases', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getConnectedTenantLeases = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--eq--]': filters.statusEq,
    'filters[status][--in--]': filters.status?.join?.(','),
    q: filters.search?.[0],
  };

  return api.get('/lease/leases/tenant', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getLeaseDocuments = (leaseUid, { page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[id][--in--]': filters.id?.join?.(','),
    q: filters.search?.[0],
  };

  return api.get(`/lease/leases/${leaseUid}/documents`, {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const createLeaseDocument = (uid, {
  type,
  name,
  documentUid,
}) => api.post(`/lease/leases/${uid}/documents`, {
  type, name, documentUid,
});

export const deleteLeaseDocument = (documentUid) => api.delete(`/lease/documents/${documentUid}`);

export const terminateLease = ({ leaseUid, data }) => api.post(`lease/leases/${leaseUid}/terminate`, data);

export const reviseLease = ({ leaseUid, data }) => api.post(`lease/leases/${leaseUid}/revision/create`, data);

export const getLeasableTenants = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    q: filters.search?.[0],
  };

  return api.get('/person/tenants/lease', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getLeaseBankInfo = (leaseUid) => api.get(`/payment/leases/${leaseUid}/banking-information`, {});

// Payment method

export const changeLeasePaymentMethod = (uid, data) => api.post(`/payment/leases/${uid}/payment/method`, data);

export const getPendingLeasePaymentMethod = (uid) => api.get(`/payment/leases/${uid}/payment/method/pending-request`, {});

export const cancelPaymentMethodChange = (uid) => api.post(`/payment/leases/${uid}/payment/method/pending-request/cancel`);

export const updateLeasePaymentMethodSDD = (uid, data) => api.post(
  `/payment/leases/${uid}/payment/method/direct-debit/update`,
  data,
);

// Delegated Tenants

export const changeTenantLeasePaymentMethod = (tenantUID, uid, data) => api.post(
  `/payment/tenant/${tenantUID}/leases/${uid}/payment/method`,
  data,
);

export const cancelTenantPaymentMethodChange = (tenantUID, uid) => api.post(
  `/payment/tenant/${tenantUID}/leases/${uid}/payment/method/pending-request/cancel`,
);

export const updateTenantLeasePaymentMethodSDD = (tenantUID, uid, data) => api.post(
  `/payment/tenant/${tenantUID}/leases/${uid}/payment/method/direct-debit/update`,
  data,
);

// Housing Benefits

export const updateLeaseHousingBenefits = (leaseUid, data) => api.post(
  `/payment/leases/${leaseUid}/housing-benefits/create`,
  data,
);
