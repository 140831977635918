/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-bracket-location */
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import {
  Picto,
  utils,
  Button,
  TextInput,
  RadioGroup,
  UnlockerLoader,
} from 'ui-library-unlocker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { displayError, isFieldValid, errorFocusSubmit } from '../../../utils/forms/form';
import { useAppContext } from '../../../store/context';
import useProfile from '../../../hooks/useProfile';

import { postToKnowYou } from '../../../services/person';

import styles from './PreOnboarding.module.scss';

const validationSchema = yup.object().shape({
  firstName: yup.string().required('global.form.errors.required'),
  lastName: yup.string().required('global.form.errors.required'),
  rentDistribution: yup
    .string()
    .oneOf([
      'with_both_personal_and_companies_account',
      'only_with_companies_account',
      'only_with_personal_account',
      'real_estate_agency_with_no_properties',
    ], 'global.form.errors.invalid')
    .required('global.form.errors.required')
    .nullable(),
});

function PreOnboarding() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const [step2, setStep2] = useState(false);
  const [alreadyProfile, setAlreadyProfile] = useState(null);
  const { profile, isLoadingProfile } = useProfile();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      rentDistribution: null,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      personalDataMutation.mutate(values);
    },
  });

  useEffect(() => {
    if (profile?.firstName && profile?.lastName) {
      formik.setFieldValue('firstName', profile.firstName);
      formik.setFieldValue('lastName', profile.lastName);
      setStep2(true);
      setAlreadyProfile(true);
    } else if (profile) {
      setAlreadyProfile(false);
    }
  }, [profile]);

  const personalDataMutation = useMutation({
    mutationFn: postToKnowYou,
    onSuccess: () => {
      dispatch({
        type: 'SET_HAS_COMPLETED_PRE_ONBOARDING',
        payload: true,
      });
      localStorage.setItem('hasCompletedPreOnboarding', true);
      navigate('/profile');
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.generic'));
    },
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    return setStep2(true);
  }, [formik]);

  const handleBack = useCallback(() => setStep2(false), []);

  const rentDistributionOptions = useMemo(() => [
    {
      id: 'only_with_personal_account',
      value: 'only_with_personal_account',
      label: t('dashboard.preOnboarding.form.rentDistributionOptions.only_with_personal_account'),
    },
    {
      id: 'only_with_companies_account',
      value: 'only_with_companies_account',
      label: t('dashboard.preOnboarding.form.rentDistributionOptions.only_with_companies_account'),
    },
    {
      id: 'with_both_personal_and_companies_account',
      value: 'with_both_personal_and_companies_account',
      label: t('dashboard.preOnboarding.form.rentDistributionOptions.with_both_personal_and_companies_account'),
    },
    {
      id: 'real_estate_agency_with_no_properties',
      value: 'real_estate_agency_with_no_properties',
      label: t('dashboard.preOnboarding.form.rentDistributionOptions.real_estate_agency_with_no_properties'),
    },
  ], [t]);

  const handleRentDistribution = useCallback((value) => {
    formik.setFieldValue('rentDistribution', value);
  }, [formik]);

  if (isLoadingProfile && alreadyProfile === null) return <UnlockerLoader size={400} />;

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.titleBlock}>
          <h1>{t('dashboard.preOnboarding.title')}</h1>
          <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
        </div>

        <h2 className="m-t-15 m-b-20">
          {!step2 ? t('dashboard.preOnboarding.subtitle') : t('dashboard.preOnboarding.form.rentDistribution')}
        </h2>

        <form onSubmit={
          formik.values.firstName && formik.values.lastName && !step2
            ? handleSubmit
            : errorFocusSubmit(formik.handleSubmit)
        }>
          {!step2 ? (
            <div className={utils.cn([styles.formGroup, 'm-t-35'])}>
              <TextInput
                type="text"
                id="firstName"
                name="firstName"
                className={styles.input}
                label={t('dashboard.preOnboarding.form.firstName')}
                error={displayError(t, formik, 'firstName')}
                valid={isFieldValid(formik, 'firstName', null, formik.initialValues?.firstName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              <TextInput
                type="text"
                id="lastName"
                name="lastName"
                className={styles.input}
                label={t('dashboard.preOnboarding.form.lastName')}
                error={displayError(t, formik, 'lastName')}
                valid={isFieldValid(formik, 'lastName', null, formik.initialValues?.lastName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
            </div>
          ) : (
            <>
              <RadioGroup
                name="rentDistribution"
                className="m-t-30"
                options={rentDistributionOptions}
                value={formik.values.rentDistribution}
                onChange={handleRentDistribution}
              />
              <p className="p-2-500 m-t-30">
                <Trans i18nKey="dashboard.preOnboarding.form.info">
                  <a href="mailto:support@unlocker.io">support@unlocker.io</a>
                </Trans>
              </p>
            </>
          )}
          <div className={utils.cn([styles.formFooter, 'm-t-50'])}>
            <Button
              type="submit"
              variant="primary"
              size="large"
              loading={formik.isSubmitting}
            >
              {step2 ? t('global.validate') : t('global.next')}
            </Button>
            {step2 && alreadyProfile !== true && (
              <p
                tabIndex={0}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                onKeyDown={null}
                className={styles.back}
                onClick={handleBack}
              >
                {t('global.goBack')}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

PreOnboarding.propTypes = {
};

export default PreOnboarding;
