import { utils } from 'ui-library-unlocker';
import api from './_client';

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @returns {Promise}
 */
export const getProperties = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    'filters[status][--eq--]': filters.statusEq?.join?.(','),
    'filters[type][--in--]': filters.type?.join?.(','),
    'filters[visibility][--in--]': filters.visibility?.join?.(','),
    'filters[rentExcludingCharges][--min--]': utils.euroToCents(filters.rentPriceMin?.[0]) || undefined,
    'filters[rentExcludingCharges][--max--]': utils.euroToCents(filters.rentPriceMax?.[0]) || undefined,
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/property/properties', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getProperty = (uid) => api.get(`/property/properties/${uid}`);

export const createProperty = (data) => api.post('/property/properties', data);

export const updateProperty = (uid, data) => api.put(`/property/properties/${uid}`, data);

export const updatePropertyCharacteristics = (uid, d) => api.put(`/property/properties/${uid}/characteristics`, d);

export const getPropertyDocuments = (uid, {
  page = null,
  itemsPerPage = null,
  filters = {},
}) => {
  const apiFilters = {
    'filters[type][--in--]': filters.type?.join?.(','),
  };

  return api.get(`/property/properties/${uid}/documents`, {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const createPropertyDocument = (uid, {
  type,
  name,
  documentUid,
  date,
}) => api.post(`/property/properties/${uid}/documents`, {
  type, name, documentUid, date,
});

export const deletePropertyDocument = (uid) => api.delete(`/property/documents/${uid}`);

export const getPropertyPictures = (uid, params) => api.get(`/property/properties/${uid}/pictures`, {
  params,
});

export const createPropertyPicture = (uid, {
  name,
  documentUid,
  main,
}) => api.post(`/property/properties/${uid}/pictures`, {
  name, documentUid, main,
});

export const deletePropertyPicture = (uid) => api.delete(`/property/pictures/${uid}`);

export const updatePropertyPicture = (uid, data) => api.put(`/property/pictures/${uid}`, data);

export const getPropertyInvoices = (uid, {
  page = null,
  itemsPerPage = null,
  filters = {},
}) => {
  const apiFilters = {
    'filters[id][--in--]': filters.id?.join?.(','),
  };

  return api.get(`/property/properties/${uid}/invoices`, {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const createPropertyInvoice = (uid, {
  type,
  name,
  documentUid,
  date,
  charge,
  amount,
}) => api.post(`/property/properties/${uid}/invoices`, {
  type, name, documentUid, date, charge, amount,
});

export const deletePropertyInvoice = (uid) => api.delete(`/property/invoices/${uid}`);

export const getLotOnboarding = () => api.get('/property/onboarding');
