/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';

// logos
import LogoHorizontal from '../../../assets/icons/logos/logo-horizontal.svg';

// pictos
import Mail from '../../../assets/icons/pictos/mail.svg';
import Notification from '../../../assets/icons/pictos/notification.svg';
import NotificationBold from '../../../assets/icons/pictos/notification-bold.svg';
import Eye from '../../../assets/icons/pictos/eye.svg';
import EyeSlash from '../../../assets/icons/pictos/eye-slash.svg';
import CloseCircle from '../../../assets/icons/pictos/close-circle.svg';
import Close from '../../../assets/icons/pictos/close.svg';
import TickCircle from '../../../assets/icons/pictos/tick-circle.svg';
import TickCircleBold from '../../../assets/icons/pictos/tick-circle-bold.svg';
import AlertCircle from '../../../assets/icons/pictos/alert-circle.svg';
import InfoCircle from '../../../assets/icons/pictos/info-circle.svg';
import Loading from '../../../assets/icons/pictos/loading.svg';
import Building4 from '../../../assets/icons/pictos/building-4.svg';
import ClipboardText from '../../../assets/icons/pictos/clipboard-text.svg';
import Faq from '../../../assets/icons/pictos/faq.svg';
import House2 from '../../../assets/icons/pictos/house-2.svg';
import Key from '../../../assets/icons/pictos/key.svg';
import Keyboard from '../../../assets/icons/pictos/keyboard.svg';
import Map from '../../../assets/icons/pictos/map.svg';
import ProfileCircle from '../../../assets/icons/pictos/profile-circle.svg';
import Receipt2 from '../../../assets/icons/pictos/receipt-2.svg';
import UserOctagon from '../../../assets/icons/pictos/user-octagon.svg';
import User from '../../../assets/icons/pictos/user.svg';
import EtatLieux from '../../../assets/icons/pictos/etat-lieux.svg';
import EtatLieuxOk from '../../../assets/icons/pictos/etat-lieux-ok.svg';
import ExportLink from '../../../assets/icons/pictos/export-link.svg';
import Logout from '../../../assets/icons/pictos/logout.svg';
import Calendar from '../../../assets/icons/pictos/calendar.svg';
import ArrowDown from '../../../assets/icons/pictos/arrow-down.svg';
import ArrowwRight from '../../../assets/icons/pictos/arrow-right.svg';
import ArrowRightBulk from '../../../assets/icons/pictos/arrow-right-bulk.svg';
import Menu from '../../../assets/icons/pictos/menu.svg';
import More from '../../../assets/icons/pictos/more.svg';
import DocumentPrevious from '../../../assets/icons/pictos/document-previous.svg';
import HouseBulk from '../../../assets/icons/pictos/house-bulk.svg';
import Plus from '../../../assets/icons/pictos/plus.svg';
import Edit from '../../../assets/icons/pictos/edit.svg';
import Trash from '../../../assets/icons/pictos/trash.svg';
import Undo from '../../../assets/icons/pictos/undo.svg';
import Redo from '../../../assets/icons/pictos/redo.svg';
import Tick from '../../../assets/icons/pictos/tick.svg';
import Setting from '../../../assets/icons/pictos/setting.svg';
import Edit2 from '../../../assets/icons/pictos/edit-2.svg';
import Import from '../../../assets/icons/pictos/import.svg';
import Document from '../../../assets/icons/pictos/document.svg';
import Timer from '../../../assets/icons/pictos/timer.svg';
import Send from '../../../assets/icons/pictos/send.svg';
import Minus from '../../../assets/icons/pictos/minus.svg';
import Search from '../../../assets/icons/pictos/search.svg';
import Camera from '../../../assets/icons/pictos/camera.svg';
import Location from '../../../assets/icons/pictos/location.svg';
import FacebookFrame from '../../../assets/icons/pictos/sn_facebook_frame.svg';
import LinkedinFrame from '../../../assets/icons/pictos/sn_linkedin_frame.svg';
import Chart from '../../../assets/icons/pictos/chart.svg';
import Wallet from '../../../assets/icons/pictos/wallet.svg';

// drawings
import TraitDroit800 from '../../../assets/icons/drawings/trait-droit-800.svg';
import TraitLogin1 from '../../../assets/icons/drawings/trait-login-1.svg';
import TraitLogin2 from '../../../assets/icons/drawings/trait-login-2.svg';
import TraitLoginTitle from '../../../assets/icons/drawings/trait-login-title.svg';
import Rond1 from '../../../assets/icons/drawings/rond-1.svg';
import PointMoyen900 from '../../../assets/icons/drawings/point-moyen-900.svg';
import Gribouillon6 from '../../../assets/icons/drawings/gribouillon-6.svg';
import Trace3567 from '../../../assets/icons/drawings/trace-3567.svg';
import Trace3511 from '../../../assets/icons/drawings/trace-3511.svg';
import Trace3512 from '../../../assets/icons/drawings/trace-3512.svg';
import Trace3513 from '../../../assets/icons/drawings/trace-3513.svg';
import Trace3902 from '../../../assets/icons/drawings/trace-3902.svg';
import Trace3472 from '../../../assets/icons/drawings/trace-3472.svg';
import TraitVague701 from '../../../assets/icons/drawings/trait-vague-701.svg';
import Trace5208 from '../../../assets/icons/drawings/trace-5208.svg';
import ScribbleCrossMedium from '../../../assets/icons/drawings/scribble-cross-medium.svg';

// editor
import EditorTypeBold from '../../../assets/icons/editor/editor-type-bold.svg';
import EditorTypeItalic from '../../../assets/icons/editor/editor-type-italic.svg';
import EditorTypeMenu from '../../../assets/icons/editor/editor-type-menu.svg';

// masks
import Mask115 from '../../../assets/icons/masks/11-1.svg';
// import MaskLease1 from '../../../assets/icons/masks/lease-1.svg';

// loaders
import LoaderAnimBlack from '../../../assets/icons/anim-unlocker-logo-noir.svg';
import LoaderAnimWhite from '../../../assets/icons/anim-unlocker-logo-blanc.svg';

export const availablePictos = {
  // logos
  'logo-horizontal': LogoHorizontal,

  // pictos
  'mail': Mail,
  'notification': Notification,
  'notification-bold': NotificationBold,
  'eye': Eye,
  'eye-slash': EyeSlash,
  'close-circle': CloseCircle,
  'close': Close,
  'tick-circle': TickCircle,
  'tick-circle-bold': TickCircleBold,
  'alert-circle': AlertCircle,
  'info-circle': InfoCircle,
  'loading': Loading,
  'building-4': Building4,
  'clipboard-text': ClipboardText,
  'faq': Faq,
  'house-2': House2,
  'key': Key,
  'keyboard': Keyboard,
  'map': Map,
  'profile-circle': ProfileCircle,
  'receipt-2': Receipt2,
  'user-octagon': UserOctagon,
  'user': User,
  'etat-lieux': EtatLieux,
  'etat-lieux-ok': EtatLieuxOk,
  'export-link': ExportLink,
  'logout': Logout,
  'calendar': Calendar,
  'arrow-down': ArrowDown,
  'arrow-right': ArrowwRight,
  'arrow-right-bulk': ArrowRightBulk,
  'menu': Menu,
  'more': More,
  'document-previous': DocumentPrevious,
  'house-bulk': HouseBulk,
  'plus': Plus,
  'minus': Minus,
  'edit': Edit,
  'trash': Trash,
  'undo': Undo,
  'redo': Redo,
  'tick': Tick,
  'setting': Setting,
  'edit-2': Edit2,
  'import': Import,
  'document': Document,
  'timer': Timer,
  'send': Send,
  'search': Search,
  'camera': Camera,
  'location': Location,
  'facebook-frame': FacebookFrame,
  'linkedin-frame': LinkedinFrame,
  'chart': Chart,
  'wallet': Wallet,

  // drawings
  'trait-droit-800': TraitDroit800,
  'trait-login-1': TraitLogin1,
  'trait-login-2': TraitLogin2,
  'trait-login-title': TraitLoginTitle,
  'rond-1': Rond1,
  'point-moyen-900': PointMoyen900,
  'gribouillon-6': Gribouillon6,
  'trace-3567': Trace3567,
  'trace-3511': Trace3511,
  'trace-3512': Trace3512,
  'trace-3513': Trace3513,
  'trace-3902': Trace3902,
  'trace-3472': Trace3472,
  'trait-vague-701': TraitVague701,
  'trace-5208': Trace5208,
  'scribble-cross-medium': ScribbleCrossMedium,

  // masks
  'mask-11-1': Mask115,

  // editor
  'editor-type-bold': EditorTypeBold,
  'editor-type-italic': EditorTypeItalic,
  'editor-type-menu': EditorTypeMenu,

  // loader
  'loaderBlack': LoaderAnimBlack,
  'loaderWhite': LoaderAnimWhite,
};

const Picto = ({ icon, ...props }) => (typeof availablePictos[icon] === 'function'
  ? React.cloneElement(availablePictos[icon](), props) : null);

Picto.propTypes = {
  icon: PropTypes.oneOf(Object.keys(availablePictos)),
};

Picto.defaultProps = {
  icon: null,
};

export default Picto;
