import { utils } from 'ui-library-unlocker';
import { getDocumentDownloadUrl } from '../services/document';

export const handleDocumentDownload = async (document, t = undefined) => {
  try {
    const { data: { presignedUrl } } = await getDocumentDownloadUrl(document?.uid);
    if (presignedUrl) {
      window.open(presignedUrl, '_blank');
    }
  } catch (error) {
    switch (error?.response?.status) {
      case 404:
        if (t) utils.toast.error(t(`global.form.errors.file.${error?.response?.data?.message}`));
        else utils.toast.error('DOCUMENT_NOT_FOUND_ERROR');
        break;
      default:
        break;
    }
  }
};
