const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.payload,
      };
    case 'SET_REFRESH_TOKEN':
      return {
        ...state,
        refreshToken: action.payload,
      };
    case 'SET_AUTH':
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        user: action.payload.user,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        user: null,
      };
    case 'SET_ME':
      return {
        ...state,
        me: action.payload,
      };
    case 'SET_SIDEMENU_OPEN':
      return {
        ...state,
        sideMenuOpen: action.payload,
      };
    case 'SET_UI_BUILDERS':
      return {
        ...state,
        uiBuilders: action.payload,
      };
    case 'SET_EMPTY_CONVERSATIONS':
      return {
        ...state,
        emptyConversations: action.payload,
      };
    case 'SET_GOOGLE_MAPS':
      return {
        ...state,
        googleMaps: action.payload,
      };
    case 'SET_HAS_COMPLETED_PRE_ONBOARDING':
      return {
        ...state,
        hasCompletedPreOnboarding: action.payload,
      };
    case 'SET_HAS_REAL_ESTATE_AGENCY':
      return {
        ...state,
        hasRealEstateAgency: action.payload,
      };
    case 'SET_SEARCH_MAP':
      return {
        ...state,
        searchMap: action.payload,
      };
    case 'SET_SEARCH_MAP_PROPERTY':
      return {
        ...state,
        searchMapProperty: action.payload,
      };
    case 'SET_ROLE_SPACE':
      return {
        ...state,
        roleSpace: action.payload,
      };
    case 'SET_NEED_DATA_TABLE_REFETCH':
      return {
        ...state,
        needDataTableRefetch: action.payload,
      };
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload,
      };
    case 'SET_TOTAL_NUMBER_OF_NOTIFICATIONS':
      return {
        ...state,
        totalNumberOfNotifications: action.payload,
      };
    case 'SET_HAS_UNREAD_NOTIFICATIONS':
      return {
        ...state,
        hasUnreadNotifications: action.payload,
      };
    case 'SET_ACCESS_TOKEN_BACKUP':
      return {
        ...state,
        accessTokenBackup: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
