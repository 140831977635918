import * as yup from 'yup';

import addressSchema from './addressSchema';

import { validatePhoneNumber } from '../validation';

const twentyYearsBackDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 20);
  return date;
};

export const personalInfoInitialValues = {
  email: '',
  lastName: '',
  firstName: '',
  secondName: '',
  birthName: '',
  mobilePhoneCountryCode: null,
  mobilePhoneNumber: '',
  phoneCountryCode: null,
  phoneNumber: null,
  title: null, // "MR" "MS"
  gender: null, // "MALE" "FEMALE" "NONE"
  marriedTo: '',
  nationality: '', // CODE COUNTRY
  birthCity: '', // string
  birthCountry: '', // CODE COUNTRY
  birthDepartment: '', // string (numéro de département)
  birthDate: twentyYearsBackDate(),
  address: {
    street: '',
    streetDetails: null,
    streetNumber: null,
    zipCode: '',
    latitude: null,
    longitude: null,
    countryCode: '',
    city: '',
  },
  taxCountry: 'FR',
  iban: '',
};

export const personalInfoSchema = yup.object().shape({
  lastName: yup.string().required('global.form.errors.required'),
  firstName: yup.string().required('global.form.errors.required'),
  birthName: yup.string().nullable(),
  secondName: yup.string().nullable(),
  mobilePhoneCountryCode: yup.string().required('global.form.errors.required').nullable(),
  mobilePhoneNumber: validatePhoneNumber('mobilePhoneNumber', 'mobilePhoneCountryCode')
    .required('global.form.errors.required'),
  phoneCountryCode: yup.string().nullable(),
  phoneNumber: validatePhoneNumber('phoneNumber', 'phoneCountryCode'),
  title: yup.string().when('$isUserTenant', {
    is: true,
    then: yup
      .string()
      .oneOf(['mr', 'ms', null], 'global.form.errors.invalid')
      .nullable(),
    otherwise: yup
      .string()
      .oneOf(['mr', 'ms'], 'global.form.errors.invalid')
      .required('global.form.errors.required')
      .nullable(),
  }),
  gender: yup.string().when('$isUserTenant', {
    is: true,
    then: yup
      .string()
      .oneOf(['male', 'female', 'none', null], 'global.form.errors.invalid')
      .nullable(),
    otherwise: yup
      .string()
      .oneOf(['male', 'female', 'none'], 'global.form.errors.invalid')
      .required('global.form.errors.required')
      .nullable(),
  }),
  marriedTo: yup.string().nullable(),
  nationality: yup.string().when('$isUserTenant', {
    is: true,
    then: yup
      .string()
      .nullable(),
    otherwise: yup
      .string()
      .required('global.form.errors.required')
      .nullable(),
  }),
  birthCity: yup.string().when('$isUserTenant', {
    is: true,
    then: yup
      .string()
      .nullable(),
    otherwise: yup
      .string()
      .required('global.form.errors.required')
      .nullable(),
  }),
  birthCountry: yup.string().when('$isUserTenant', {
    is: true,
    then: yup
      .string()
      .nullable(),
    otherwise: yup
      .string()
      .required('global.form.errors.required')
      .nullable(),
  }),
  birthDepartment: yup.string().when('$isUserTenant', {
    is: true,
    then: yup
      .string()
      .nullable(),
    otherwise: yup
      .string()
      .required('global.form.errors.required')
      .nullable(),
  }),
  birthDate: yup
    .date()
    .max(
      new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
      'profile.personalInfo.errors.underaged',
    )
    .required('global.form.errors.required')
    .nullable(),
  taxCountry: yup.string().required('global.form.errors.required').nullable(),
  address: addressSchema.when('$isUserTenant', {
    is: true,
    then: addressSchema.nullable(),
    otherwise: addressSchema
      .required('global.form.errors.required')
      .nullable(),
  }),
  iban: yup.string()
    .when(['rentDistribution', '$isUserTenant'], {
      is: (rentDistribution, isUserTenant) => (
        !isUserTenant
        && [
          'only_with_personal_account',
          'with_both_personal_and_companies_account',
        ].includes(rentDistribution)
      ),
      then: yup.string()
        .length(27, 'global.form.errors.iban')
        .test({
          message: 'global.form.errors.ibanFormat',
          test: function test(value) {
            return value?.length > 0 ? value.startsWith('FR') : true;
          },
        })
        .required('global.form.errors.required')
        .nullable(),
      otherwise: yup.string()
        .test({
          name: 'iban-format',
          test: function test(value, ctx) {
            if (!value) return true;
            if (!value.startsWith('FR')) return ctx.createError({ message: 'global.form.errors.ibanFormat' });
            if (value.length !== 27) return ctx.createError({ message: 'global.form.errors.iban' });
            return true;
          },
        })
        .nullable(),
    }),
});

export const ownerInvitePersonalInfoSchema = yup.object().shape({
  lastName: yup.string().required('global.form.errors.required'),
  firstName: yup.string().required('global.form.errors.required'),
  mobilePhoneCountryCode: yup.string().required('global.form.errors.required').nullable(),
  mobilePhoneNumber: validatePhoneNumber('mobilePhoneNumber', 'mobilePhoneCountryCode')
    .required('global.form.errors.required'),
});
