/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation, Trans } from 'react-i18next';
import {
  Picto,
  Tag,
  utils,
  Button,
  UnlockerLoader,
} from 'ui-library-unlocker';
import RegisterPaymentModal from '../../components/templates/RegisterPaymentModal/RegisterPaymentModal';
import DashboardBasics from '../../components/organisms/DashboardBasics/DashboardBasics';
import PreOnboarding from './PreOnboarding/PreOnboarding';
import { useAppContext } from '../../store/context';

import useProfileCompletion from '../../hooks/useProfileCompletion';
import useRoles from '../../hooks/useRoles';
import useProfile from '../../hooks/useProfile';

import { showModal } from '../../utils/modal';
import { validateOnboardingUserStatus } from '../../services/identity';

import styles from './Dashboard.module.scss';
import AdminKPI from './AdminKPI/AdminKPI';

function Dashboard() {
  const { t } = useTranslation();
  const { profile } = useProfile();
  const {
    onboardingStatus,
  } = useProfileCompletion();
  const {
    isUserAdmin,
    isUserLessor,
    isUserTenant,
  } = useRoles();
  const { context: { me, hasCompletedPreOnboarding } } = useAppContext();

  useEffect(() => {
    document.title = t('dashboard.browserTitle');
  }, [t]);

  const validateOnboardingMutation = useMutation({
    mutationFn: validateOnboardingUserStatus,
  });

  const formStatus = useMemo(() => {
    if (!onboardingStatus) return null;
    switch (onboardingStatus) {
      case 'pending':
        return {
          variant: 'tertiary',
        };
      case 'profile_done':
        return {
          variant: 'primary',
        };
      case 'enrolment_requested':
        return {
          variant: 'tertiary',
        };
      case 'enrolment_pending':
        return {
          variant: 'tertiary',
        };
      case 'enrolment_challenged':
        return {
          variant: 'tertiary',
        };
      case 'enrolment_denied':
        return {
          variant: 'error',
        };
      case 'enrolment_bypassed':
      case 'completed':
        return {
          variant: 'success',
        };
      default:
        return {
          variant: 'tertiary',
        };
    }
  }, [onboardingStatus]);

  const userOnboardingCompletedStatus = useMemo(() => {
    if (validateOnboardingMutation?.isSuccess) return true;
    return me?.userOnboardingCompletedStatus;
  }, [me, validateOnboardingMutation]);

  const renderNoBasics = useMemo(() => (
    <>
      <p className={utils.cn(['m-t-40 p-1-300', styles.descriptions])}>
        {t(`dashboard.descriptions.${onboardingStatus}`)}
      </p>
      {onboardingStatus === 'pending' && (
      <Link to="/profile#personal-info">
        <Button className="m-t-20" variant="primary" size="large">
          {t('completeProfile.cta')}
        </Button>
      </Link>
      )}
      <img className={styles.mask} src="/images/appartment-1.png" alt="cover" />
    </>
  ), [onboardingStatus, isUserAdmin]);

  const handleValidateOnboardingUserStatus = useCallback(() => {
    validateOnboardingMutation.mutate();
  }, [validateOnboardingMutation]);

  if (typeof hasCompletedPreOnboarding === 'undefined') return <UnlockerLoader size={300} />;

  if (!hasCompletedPreOnboarding && isUserLessor) return <PreOnboarding />;

  if (!onboardingStatus) return <UnlockerLoader size={300} />;

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.titleBlock}>
          <h1>{t(isUserAdmin ? 'dashboard.adminTitle' : 'dashboard.title')}</h1>
          {!isUserAdmin && formStatus && (
            <Tag
              label={t(`profile.profileState.${onboardingStatus}`)}
              size="medium"
              variant={formStatus?.variant}
            />
          )}
        </div>
        {isUserLessor && !isUserAdmin && (
          <Button
            variant="primary"
            size="medium"
            onClick={() => showModal('dashboard-register-payment-modal')}
            className={styles.registerPaymentBtn}
          >
            {t('dashboard.registerPayment.cta')}
          </Button>
        )}
      </div>

      <div className={styles.content}>

        <h2 className={utils.cn(['m-t-10 m-b-4', styles.welcomeTitle])}>
          {profile?.firstName ? t('dashboard.titles.profile_done', { firstName: profile?.firstName }) : t('dashboard.titles.pending')}
        </h2>

        <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />

        {isUserTenant && (
          <div className="m-t-20">
            <p>
              <Trans i18nKey="global.alsoMobileApp" />
            </p>
            <div className={styles.userCtas}>
              <a
                className={styles.storePill}
                href="https://apps.apple.com/us/app/unlocker/id1667221898"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/cta-app-store.png"
                  alt="Download on the App Store"
                />
              </a>
              <a
                className={styles.storePill}
                href="https://play.google.com/store/apps/details?id=com.unlockerapp"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="m-l-20"
                  src="/images/cta-google-play.png"
                  alt="Get it on Google Play"
                />
              </a>
            </div>
          </div>
        )}

        {(userOnboardingCompletedStatus === false && isUserLessor) && (
          <>
            <DashboardBasics
              handleValidateOnboardingUserStatus={handleValidateOnboardingUserStatus}
              validating={validateOnboardingMutation.isLoading}
              className={styles.dashboardBasics}
            />
            {!isUserAdmin && (
              <Picto width={166} icon="gribouillon-6" fill="var(--color-secondary)" className={styles.deco} />
            )}
          </>
        )}

        {userOnboardingCompletedStatus === true && !isUserAdmin && (
          renderNoBasics
        )}

      </div>

      {isUserLessor && !isUserAdmin && (
        <RegisterPaymentModal id="dashboard-register-payment-modal" />
      )}

      {isUserAdmin && (
        <AdminKPI />
      )}
    </div>
  );
}

Dashboard.propTypes = {
};

export default Dashboard;
