/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useMemo, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import {
  utils,
  TextInput,
  Button,
  CheckboxGroup,
  ToggleGroup,
  Message,
  DateInput,
} from 'ui-library-unlocker';

// Context
import { useAppContext } from '../../../../store/context';

// Components
import FormInfoRequired from '../../../atoms/FormInfoRequired/FormInfoRequired';
import DocumentList from '../../DocumentList/DocumentList';
import Modal from '../../../molecules/Modal/Modal';
import FileInput from '../../../molecules/FileInput/FileInput';
import ConsumptionScore from '../../../atoms/ConsumptionScore/ConsumptionScore';

// Hooks
import useResponsive from '../../../../hooks/useResponsive';
import useScroll from '../../../../hooks/useScroll';
import useFileUpload from '../../../../hooks/useFileUpload';
import useRoles from '../../../../hooks/useRoles';

// Services
import {
  getProperty,
  getPropertyDocuments,
  updatePropertyCharacteristics,
  deletePropertyDocument,
  createPropertyDocument,
} from '../../../../services/property';
import {
  getPropertyFromAdmin,
  updatePropertyCharacteristicsFromAdmin,
} from '../../../../services/admin';

// Utils
import {
  propertyCharacteristicsSchema,
  propertyCharacteristicsInitialValues,
} from '../../../../utils/forms/propertySchema';
import { displayError, isFieldValid } from '../../../../utils/forms/form';
import { sortObjectKeysByValueAsc } from '../../../../utils/sort';
import { showModal, hideModal } from '../../../../utils/modal';
import { handleDocumentDownload } from '../../../../utils/documents';

import { getDocumentSchema, documentInitialValues } from '../../../../views/Property/PropertyDocuments/documentSchema';

import styles from './PropertyForm.module.scss';

function PropertyCharacteristicsForm() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();
  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });
  const propertyBuilders = useMemo(() => (uiBuilders ? uiBuilders['/property/ui'] : null), [uiBuilders]);
  const { isMobile, isLaptopXs } = useResponsive();
  const { scrollContentToTop } = useScroll();
  const [isHugeDocument, setHugeDocument] = useState(false);

  const { isUserAdmin } = useRoles();

  const match = useMatch('/property/:id');
  const addDocumentModalId = 'add-ges-dpe-modal';

  const {
    data: propertyData,
    error: propertyError,
    isFetched: propertyIsFetched,
    refetch: propertyRefetch,
  } = useQuery({
    queryKey: ['property-id'],
    queryFn: () => {
      if (match?.params?.id !== 'add') {
        if (isUserAdmin) {
          return getPropertyFromAdmin(match?.params?.id);
        }
        return getProperty(match?.params?.id);
      }
      return null;
    },
    cacheTime: 0,
  });

  const DOCUMENT_TYPE = 'propertyDocument';

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['property-documents', user],
    queryFn: async () => {
      if (user) {
        return getPropertyDocuments(match?.params?.id, {
          filters: {
            type: ['ges_disgnostic', 'dpe_disgnostic'],
          },
        });
      }
      return [];
    },
    enabled: !!user,
    keepPreviousData: true,
  });

  const resetDocumentModal = () => {
    hideModal(addDocumentModalId);
    setFileType(null);
    setFile(null);
    setHugeDocument(false);
    diagnosisDocumentFormik.resetForm();
  };

  const createDocumentCb = async (documentUid, fileName) => {
    const createdDocument = await createPropertyDocument(match?.params?.id, {
      type: fileType,
      name: fileName,
      documentUid,
      date: new Date(),
    });
    refetchDocumentList();
    resetDocumentModal();
    return createdDocument;
  };

  const [{
    uploadFile,
    setFileType,
    setFile,
  }, {
    fileType,
    file,
    isUploading,
  }] = useFileUpload(createDocumentCb);

  const documentDeleteMutation = useMutation({
    mutationFn: deletePropertyDocument,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('global.documents.delete.success'));
          refetchDocumentList();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const diagnosisDocumentInitialValues = useMemo(() => {
    if (user) {
      return {
        ...documentInitialValues,
        userUid: user?.username,
      };
    }
    return documentInitialValues;
  }, [user]);

  const diagnosisDocumentFormik = useFormik({
    initialValues: diagnosisDocumentInitialValues,
    validationSchema: getDocumentSchema({ documentType: ['ges_disgnostic', 'dpe_disgnostic'] }),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        uploadFile({
          ...values,
          type: DOCUMENT_TYPE,
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  useEffect(() => {
    if (fileType) diagnosisDocumentFormik.setFieldValue('type', fileType);
  }, [fileType]);

  const propertyMutation = useMutation({
    mutationFn: (data) => {
      if (isUserAdmin) {
        return updatePropertyCharacteristicsFromAdmin(match?.params?.id, data);
      }
      return updatePropertyCharacteristics(match?.params?.id, data);
    },
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          propertyRefetch().then(() => {
            setStatusMessage({ displayed: true, value: t('property.tabs.generalInfo.formSuccess') });
            scrollContentToTop();
          });
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const initialValues = useMemo(() => {
    if (propertyError) return propertyData;
    if (propertyData) {
      const data = {};
      Object.keys(propertyCharacteristicsInitialValues).forEach((key) => {
        data[key] = propertyData?.data?.[key];
      });
      return data;
    }
    return propertyCharacteristicsInitialValues;
  }, [user, propertyData]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        propertyCharacteristicsSchema.validateSync(values, {
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, error) => {
          errors[error.path] = error.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      propertyMutation.mutate(values);
    },
  });

  const equipmentsOptions = useMemo(() => {
    if (!propertyBuilders) return [];
    const equipments = propertyBuilders?.propertyEquipments;
    if (equipments) {
      return sortObjectKeysByValueAsc(equipments).map((equipmentKey) => ({
        id: equipmentKey,
        label: equipments[equipmentKey],
        value: equipmentKey,
      }));
    }
    return [];
  }, [propertyBuilders]);

  const heatingsOptions = useMemo(() => {
    if (!propertyBuilders) return [];
    const heatings = propertyBuilders?.propertyHeatings;
    if (heatings) {
      return sortObjectKeysByValueAsc(heatings).map((equipmentKey) => ({
        id: equipmentKey,
        label: heatings[equipmentKey],
        value: equipmentKey,
      }));
    }
    return [];
  }, [uiBuilders]);

  const equipmentItemsPerRow = useMemo(() => {
    if (isMobile) return 1;
    if (isLaptopXs) return 3;
    return 4;
  }, [isMobile, isLaptopXs]);

  const columns = useMemo(() => (documentType) => ([
    {
      header: t('global.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 1,
      enableSorting: false,
      cell: () => {
        if (!uiBuilders) return null;
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('global.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 400,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
  ]), [t, uiBuilders]);

  const handleFileSelect = (e) => {
    const f = e?.target?.files[0];

    if (f?.size > 10000000) {
      utils.toast.error(t('global.form.errors.file.tooLarge'));
      setHugeDocument(true);
      return;
    }

    if (f) {
      const setFileFields = (image = null) => {
        const dim = !image
          ? {}
          : {
            width: image.naturalWidth,
            height: image.naturalHeight,
          };

        diagnosisDocumentFormik.setFieldValue('filename', f?.name);
        diagnosisDocumentFormik.setFieldValue('metadata', {
          documentSize: f?.size,
          extension: f?.type?.split('/')[1],
          ...dim,
        });
        setFile(f);

        if (image) image.removeEventListener('load', setFileFields);
      };

      if (f?.type === 'application/pdf') {
        const reader = new FileReader();
        reader.onload = () => setFileFields(null);
        reader.readAsText(f);
      } else {
        const image = new Image();
        image.src = URL.createObjectURL(f);
        image.addEventListener('load', () => setFileFields(image));
      }
    } else {
      setFile(null);
      diagnosisDocumentFormik.setFieldValue('filename', '');
      diagnosisDocumentFormik.setFieldValue('metadata', null);
    }
  };

  const handleReferenceYearChange = (date) => {
    if (date) formik.setFieldValue('referenceYear', date.getFullYear());
    else formik.setFieldValue('referenceYear', date);
  };

  if (!propertyIsFetched) return <div className={styles.wrapper}>{t('global.loading')}</div>;

  return (
    <div className={styles.wrapper}>
      <form className={styles.caracteristicsForm} onSubmit={formik.handleSubmit}>
        {/* Additional Info */}
        <h2 className="m-b-20">{t('property.tabs.characteristics.formSections.additionalInfo')}</h2>
        <FormInfoRequired className="m-b-10" content={t('property.crud.form.equipments')} />
        <CheckboxGroup
          name="equipments"
          className="m-t-25"
          options={equipmentsOptions}
          value={formik?.values?.equipments}
          onChange={(value) => formik.setFieldValue('equipments', value)}
          display="horizontal"
          itemsPerRow={equipmentItemsPerRow}
        />

        {/* Heating Type */}
        <h2 className="m-t-50 m-b-20">{t('property.tabs.characteristics.formSections.heatingType')}</h2>
        <FormInfoRequired className="m-b-10" content={t('property.crud.form.heatings')} />
        <ToggleGroup
          name="heatings"
          className="m-t-25"
          options={heatingsOptions}
          value={formik?.values?.heatings}
          onChange={(value) => formik.setFieldValue('heatings', value)}
          display="horizontal"
          itemsPerRow={equipmentItemsPerRow}
        />

        {/* GES */}
        <h2 className="m-t-50 m-b-20">{t('property.tabs.characteristics.formSections.ges')}</h2>
        <FormInfoRequired className="m-b-40" content={t('global.documents.info')} />
        <TextInput
          min="0"
          type="number"
          id="gesScore"
          name="gesScore"
          className={utils.cn([styles.formInput, 'm-t-25'])}
          label={t('property.crud.form.gesScore')}
          error={displayError(t, formik, 'gesScore')}
          valid={isFieldValid(formik, 'gesScore', null, initialValues?.gesScore)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.gesScore}
        />
        <ConsumptionScore
          className="m-t-25"
          score={formik.values.gesScore}
          type="ges"
        />
        <DocumentList
          className="m-t-25"
          fileList={['ges_disgnostic']}
          columns={columns(t('property.tabs.characteristics.documents.ges'))}
          documentListData={documentListData}
          addButton={{
            enabled: true,
            label: t('property.tabs.characteristics.addGes'),
            icon: 'plus',
          }}
          onAddBtnClick={() => {
            const docType = 'ges_disgnostic';
            setFileType(docType);
            formik.setFieldValue('type', docType);
            showModal(addDocumentModalId);
          }}
          onFileListElementAddClick={(element) => {
            const { type: documentType } = element;
            setFileType(documentType);
            formik.setFieldValue('type', documentType);
            showModal(addDocumentModalId);
          }}
          onFileListElementDelete={(element) => {
            documentDeleteMutation.mutate(element?.uid);
          }}
          pagination={null}
          isDocumentListFetching={documentListFetching}
        />

        {/* DPE */}
        <h2 className="m-t-50 m-b-20">{t('property.tabs.characteristics.formSections.dpe')}</h2>
        <FormInfoRequired className="m-b-40" content={t('global.documents.info')} />
        <TextInput
          min="0"
          type="number"
          id="dpeScore"
          name="dpeScore"
          className={utils.cn([styles.formInput, 'm-t-25'])}
          label={t('property.crud.form.dpeScore')}
          error={displayError(t, formik, 'dpeScore')}
          valid={isFieldValid(formik, 'dpeScore', null, initialValues?.dpeScore)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dpeScore}
        />
        <ConsumptionScore
          className="m-t-25"
          score={formik.values.dpeScore}
          type="dpe"
        />
        <DocumentList
          className="m-t-25"
          fileList={['dpe_disgnostic']}
          columns={columns(t('property.tabs.characteristics.documents.dpe'))}
          documentListData={documentListData}
          addButton={{
            enabled: true,
            label: t('property.tabs.characteristics.addDpe'),
            icon: 'plus',
          }}
          onAddBtnClick={() => {
            const docType = 'dpe_disgnostic';
            setFileType(docType);
            formik.setFieldValue('type', docType);
            showModal(addDocumentModalId);
          }}
          onFileListElementAddClick={(element) => {
            const { type: documentType } = element;
            setFileType(documentType);
            formik.setFieldValue('type', documentType);
            showModal(addDocumentModalId);
          }}
          onFileListElementDelete={(element) => {
            documentDeleteMutation.mutate(element?.uid);
          }}
          pagination={null}
          isDocumentListFetching={documentListFetching}
        />

        <TextInput
          min="0"
          type="number"
          id="minConsumption"
          name="minConsumption"
          className={utils.cn([styles.formInput, 'm-t-40'])}
          label={t('property.crud.form.minConsumption')}
          error={displayError(t, formik, 'minConsumption')}
          valid={isFieldValid(formik, 'minConsumption', null, initialValues?.minConsumption)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.minConsumption}
        />

        <TextInput
          min="0"
          type="number"
          id="maxConsumption"
          name="maxConsumption"
          className={utils.cn([styles.formInput, 'm-t-25'])}
          label={t('property.crud.form.maxConsumption')}
          error={displayError(t, formik, 'maxConsumption')}
          valid={isFieldValid(formik, 'maxConsumption', null, initialValues?.maxConsumption)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.maxConsumption}
        />
        <DateInput
          id="referenceYear"
          name="referenceYear"
          className={utils.cn(['m-t-25'])}
          label={t('property.crud.form.referenceYear')}
          error={displayError(t, formik, 'referenceYear')}
          valid={isFieldValid(formik, 'referenceYear', null, initialValues?.referenceYear)}
          onChange={handleReferenceYearChange}
          onBlur={formik.handleBlur}
          value={formik.values.referenceYear ? new Date(formik.values.referenceYear, 0, 1) : null}
          yearOnly
        />

        <div className={styles.submit}>
          <Button
            type="submit"
            size="large"
            loading={formik.isSubmitting}
            label={t('property.tabs.characteristics.formSections.submit')}
          />
          <p
            tabIndex={0}
            role="button"
            onKeyDown={null}
            className={styles.cancel}
            onClick={() => {
              formik.resetForm();
              utils.toast.info(t('profile.personalInfo.form.cancelMessage'));
            }}
          >
            <a href="#characteristics">
              {t('global.cancel')}
            </a>
          </p>
        </div>
      </form>
      <div className={styles.message}>
        {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
      </div>
      <Modal
        className={styles.modal}
        id={addDocumentModalId}
        title={isHugeDocument
          ? t('profile.documents.addDocumentForm.errors.size.title')
          : t(
            'property.tabs.characteristics.documents.info',
            {
              fileType: fileType === 'ges_disgnostic'
                ? t('property.tabs.characteristics.documents.ges')
                : t('property.tabs.characteristics.documents.dpe'),
            },
          )}
        size="large"
        onClose={resetDocumentModal}
      >
        {!isHugeDocument ? (
          <form onSubmit={diagnosisDocumentFormik.handleSubmit} className={styles.addForm}>
            <TextInput
              type="text"
              id="customName"
              name="customName"
              className="m-t-25"
              label={t('profile.documents.addDocumentForm.fields.name')}
              error={displayError(t, diagnosisDocumentFormik, 'customName')}
              valid={isFieldValid(diagnosisDocumentFormik, 'customName', null, initialValues?.customName)}
              onChange={diagnosisDocumentFormik.handleChange}
              onBlur={diagnosisDocumentFormik.handleBlur}
              value={diagnosisDocumentFormik.values.customName}
            />
            <FileInput
              className="m-t-25"
              id="profile-file"
              name="profile-file"
              label={t('profile.documents.addDocumentForm.fields.file')}
              help={t('profile.documents.addDocumentForm.fileHelp')}
              accept="image/jpg,image/png,image/jpeg,application/pdf"
              value={file}
              onChange={handleFileSelect}
            />
            <div className={styles.submitBtn}>
              <Button
                type="submit"
                loading={isUploading}
                className="m-t-25"
                label={t('profile.documents.addDocumentForm.submit')}
              />
            </div>
          </form>
        ) : (
          <div className={styles.hugeDocument}>
            <p className={styles.errorModalSubtitle}>{t('profile.documents.addDocumentForm.errors.size.message')}</p>
            <div className={styles.submitBtn}>
              <Button
                className="m-t-25"
                label={t('profile.documents.addDocumentForm.errors.size.action')}
                onClick={() => {
                  diagnosisDocumentFormik.resetForm();
                  setFileType(null);
                  setFile(null);
                  setHugeDocument(false);
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

PropertyCharacteristicsForm.propTypes = {
};

export default PropertyCharacteristicsForm;
