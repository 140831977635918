/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useMemo, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useMatches } from 'react-router-dom';
import { isEmpty } from 'lodash';
import TagManager from 'react-gtm-module';
import {
  utils,
  TextInput,
  SelectInput,
  PhoneInput,
  DateInput,
  Button,
  Message,
  UnlockerLoader,
} from 'ui-library-unlocker';

// Components
import CityInput from '../../../molecules/CityInput/CityInput';
import AddressInput from '../../../molecules/AddressInput/AddressInput';
import IbanInput from '../../../molecules/IbanInput/IbanInput';

// Context
import { useAppContext } from '../../../../store/context';

// Hooks
import useScroll from '../../../../hooks/useScroll';
import useRoles from '../../../../hooks/useRoles';

// Services
import { createTenant, updateTenant } from '../../../../services/tenant';
import { checkExistingEmail, createInvitation } from '../../../../services/identity';
import { updateTenantFromAdmin } from '../../../../services/admin';

// Utils
import tenantSchema, { tenantInitialValues } from '../../../../utils/forms/tenantSchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';
import { COUNTRIES, NATIONALITIES, DEPARTMENTS } from '../../../../utils/countries';

import styles from './TenantForm.module.scss';

function TenantForm({
  isEditing,
  tenantQuery,
}) {
  const { t } = useTranslation();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();
  const { context: { user, me } } = useAppContext();
  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { scrollContentToTop } = useScroll();
  const ibanRef = useRef(null);

  const match = useMatches()?.find((m) => m.pathname === pathname);

  const userType = useMemo(() => {
    if (isUserAdmin) return 'admin';
    if (isUserRealEstateManager) return 'manager';
    return 'owner';
  }, [isUserAdmin, isUserRealEstateManager]);

  const {
    data: tenantData,
    error: tenantError,
    isFetched: tenantIsFetched,
    refetch: tenantRefetch,
  } = tenantQuery || {};

  const twentyYearsBackDate = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 20);
    return date;
  }, []);

  const userHasDelegation = useMemo(() => (
    tenantData?.data?.isDelegated
    && user?.username === tenantData?.data?.delegatedTo
  ), [tenantData, user]);

  const userCanEdit = useMemo(() => (
    isUserAdmin
    || userHasDelegation
    || !isEditing
  ), [isUserAdmin, userHasDelegation, isEditing]);

  const initialValues = useMemo(() => {
    if (tenantError) return tenantData;
    if (tenantData) {
      const tenantBirthDate = tenantData.data?.birthDate;
      return {
        ...(tenantData.data || {}),
        birthDate: tenantBirthDate ? new Date(tenantBirthDate) : twentyYearsBackDate,
        taxCountry: 'FR',
        address: tenantData.data?.address || null,
      };
    }
    if (user) {
      return {
        ...tenantInitialValues,
        ownerUid: user?.username,
        managerUid: user?.username,
      };
    }
    return tenantInitialValues;
  }, [user, tenantData, twentyYearsBackDate, tenantError]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        tenantSchema.validateSync(values, {
          context: {
            isNew: !tenantData,
          },
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, error) => {
          errors[error.path] = error.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      tenantMutation.mutate(values);
    },
  });

  const genderOptions = useMemo(() => [{
    value: 'male',
    label: t('profile.personalInfo.form.gender.male'),
  }, {
    value: 'female',
    label: t('profile.personalInfo.form.gender.female'),
  }, {
    value: 'none',
    label: t('profile.personalInfo.form.gender.other'),
  }], [t]);

  const titleOptions = useMemo(() => [{
    value: 'mr',
    label: t('profile.personalInfo.form.title.mr'),
  }, {
    value: 'ms',
    label: t('profile.personalInfo.form.title.ms'),
  }, {
    value: 'undefined',
    label: t('profile.personalInfo.form.title.undefined'),
  }], [t]);

  const countryOptions = useMemo(() => Object.keys(COUNTRIES).map((countryCode) => ({
    value: countryCode,
    label: COUNTRIES[countryCode],
  })), [t]);

  const nationalityOptions = useMemo(() => Object.keys(NATIONALITIES).map((countryCode) => ({
    value: countryCode,
    label: NATIONALITIES[countryCode],
  })), [t]);

  const birthDepartmentOptions = useMemo(() => DEPARTMENTS.map((dpt) => ({
    value: dpt.code,
    label: dpt.nom,
  })), [t]);

  const birthDateValue = useMemo(() => {
    try {
      if (!formik.values.birthDate) return null;
      return new Date(formik.values.birthDate);
    } catch {
      return null;
    }
  }, [formik.values.birthDate]);

  const checkEmailMutation = useMutation({
    mutationFn: (data) => checkExistingEmail(data),
    keepPreviousData: true,
  });

  const sendInviteMutation = useMutation({
    mutationFn: (guestEmail) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'tenant_invite_sent',
          userType,
        },
      });
      return createInvitation({ guestEmail, relation: 'tenant' });
    },
    onSuccess: () => {
      utils.toast.success(t('tenant.sendInvite.success'));
      navigate('/tenant');
    },
    onError: (err) => {
      if (err?.response) {
        utils.toast.error(t('tenant.sendInvite.error'));
      }
    },
  });

  useEffect(() => {
    if (isEditing) return () => {};
    const delayDebounceFn = setTimeout(async () => {
      if (formik.values.email?.length < 4) return;
      try {
        checkEmailMutation.mutate({ email: formik.values.email });
        formik.setFieldTouched('email', true);
      } catch (err) {
        if (err?.response) {
          //
        }
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [formik.values.email]);

  const doesEmailExist = useMemo(() => {
    if (!formik.values.email) return false;
    if (checkEmailMutation) {
      return !!checkEmailMutation?.data;
    }
    return false;
  }, [checkEmailMutation.data, formik.values.email]);

  const tenantMutation = useMutation({
    mutationFn: (data) => {
      if (isEditing) {
        if (isUserAdmin) return updateTenantFromAdmin(match?.params?.id, data);
        return updateTenant(match?.params?.id, data);
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'tenant_created',
          userType,
        },
      });
      return createTenant(data);
    },
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('tenant.crud.formAddSuccess'));
          navigate('/tenant');
          break;
        case 204:
          tenantRefetch().then(() => {
            setStatusMessage({ displayed: true, value: t('tenant.crud.formSuccess') });
            scrollContentToTop();
            ibanRef.current?.resetEditMode();
          });
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            formik.setErrors(err?.response?.data?.errors);
            break;
          }
          case 409: {
            utils.toast.error(t('tenant.crud.formErrors.emailAlreadyExists'));
            break;
          }
          default:
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const isFormError = useMemo(() => !isEmpty(formik.errors), [formik.errors]);

  if (!tenantIsFetched) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className={styles.wrapper}>
      <div>
        {userHasDelegation && (
          <Message
            variant="info"
            content={t('tenant.crud.delegated')}
            className="m-b-20"
          />
        )}
        <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
          {isEditing && (
            <h2 className={utils.cn(['m-b-30', styles.formTitleWidth])}>{t('tenant.tabs.generalInfo.title')}</h2>
          )}
          {isEditing && (
            <TextInput
              type="text"
              id="unlockerId"
              name="unlockerId"
              className="m-t-35"
              label={t('profile.personalInfo.form.unlockerId')}
              value={formik.values.unlockerId}
              disabled
            />
          )}
          <TextInput
            type="email"
            id="email"
            name="email"
            disabled={isEditing}
            className="m-t-25"
            label={t('tenant.crud.form.email')}
            error={!checkEmailMutation.isLoading && displayError(t, formik, 'email')}
            valid={doesEmailExist ? null : isFieldValid(formik, 'email', null, initialValues?.email)}
            info={(
              !isEditing
              && !doesEmailExist
              && isFieldValid(formik, 'email', null, initialValues.email)
            ) ? t('global.emailAvailable') : null}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            loading={checkEmailMutation.isLoading}
          />
          { (!doesEmailExist && !isEditing) || isEditing ? (
            <>
              <TextInput
                type="text"
                id="firstName"
                name="firstName"
                className="m-t-25"
                label={t('tenant.crud.form.firstName')}
                error={displayError(t, formik, 'firstName')}
                valid={isFieldValid(formik, 'firstName', null, initialValues?.firstName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                disabled={!userCanEdit}
              />
              {tenantData !== null && (
                <TextInput
                  type="text"
                  id="secondName"
                  name="secondName"
                  className="m-t-25"
                  label={`${t('profile.personalInfo.form.secondName')} ${t('global.form.optional')}`}
                  error={displayError(t, formik, 'secondName')}
                  valid={isFieldValid(formik, 'secondName', null, initialValues?.secondName)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondName}
                  disabled={!userCanEdit}
                />
              )}
              <TextInput
                type="text"
                id="lastName"
                name="lastName"
                className="m-t-25"
                label={t('tenant.crud.form.lastName')}
                error={displayError(t, formik, 'lastName')}
                valid={isFieldValid(formik, 'lastName', null, initialValues?.lastName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                disabled={!userCanEdit}
              />
              {tenantData !== null && (
                <TextInput
                  type="text"
                  id="birthName"
                  name="birthName"
                  className="m-t-25"
                  label={`${t('profile.personalInfo.form.birthName')} ${t('global.form.optional')}`}
                  error={displayError(t, formik, 'birthName')}
                  valid={isFieldValid(formik, 'birthName', null, initialValues?.birthName)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birthName}
                  disabled={!userCanEdit}
                />
              )}
              <PhoneInput
                type="text"
                id="mobilePhoneNumber"
                name="mobilePhoneNumber"
                className="m-t-25"
                label={t('tenant.crud.form.mobilePhone')}
                error={displayError(t, formik, 'mobilePhoneNumber')}
                valid={isFieldValid(formik, 'mobilePhoneNumber', null, initialValues?.mobilePhoneNumber)}
                onChange={(value) => {
                  const { countryCode, phone } = value;
                  formik.setFieldValue('mobilePhoneCountryCode', countryCode);
                  formik.setFieldValue('mobilePhoneNumber', phone);
                }}
                onBlur={formik.handleBlur}
                value={{
                  countryCode: formik?.values?.mobilePhoneCountryCode,
                  phone: formik?.values?.mobilePhoneNumber,
                }}
                disabled={!userCanEdit}
              />
              <PhoneInput
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                className="m-t-25"
                label={`${t('tenant.crud.form.phone')} ${t('global.form.optional')}`}
                error={displayError(t, formik, 'phoneNumber')}
                valid={isFieldValid(formik, 'phoneNumber', null, initialValues?.phoneNumber)}
                value={{
                  countryCode: formik?.values?.phoneCountryCode,
                  phone: formik?.values?.phoneNumber,
                }}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  const { countryCode, phone } = value;
                  formik.setFieldValue('phoneCountryCode', countryCode);
                  formik.setFieldValue('phoneNumber', phone);
                }}
                disabled={!userCanEdit}
              />
              {tenantData !== null ? (
                <>
                  <h2 className="m-t-40">{t('profile.personalInfo.civilState')}</h2>
                  <SelectInput
                    id="gender"
                    name="gender"
                    className="m-t-25"
                    label={`${t('profile.personalInfo.form.gender.label')} ${t('global.form.optional')}`}
                    options={genderOptions}
                    error={displayError(t, formik, 'gender')}
                    valid={isFieldValid(formik, 'gender', null, initialValues?.gender)}
                    onChange={(value) => {
                      formik.setFieldValue('gender', value ? value.value : null);
                      if (value?.value === 'male') formik.setFieldValue('title', 'mr');
                      if (value?.value === 'female') formik.setFieldValue('title', 'ms');
                    }}
                    onBlur={formik.handleBlur}
                    value={genderOptions.find((gender) => gender.value === formik.values.gender)}
                    isClearable
                    disabled={!userCanEdit}
                  />
                  <SelectInput
                    id="title"
                    name="title"
                    className="m-t-25"
                    label={`${t('profile.personalInfo.form.title.label')} ${t('global.form.optional')}`}
                    options={titleOptions}
                    error={displayError(t, formik, 'title')}
                    valid={isFieldValid(formik, 'title', null, initialValues?.title)}
                    onChange={(value) => {
                      formik.setFieldValue('title', value ? value.value : null);
                    }}
                    onBlur={formik.handleBlur}
                    value={titleOptions.find((title) => title.value === formik.values.title)}
                    isClearable
                    disabled={!userCanEdit}
                  />
                  <TextInput
                    type="text"
                    id="marriedTo"
                    name="marriedTo"
                    className="m-t-25"
                    label={`${t('profile.personalInfo.form.marriedTo')} ${t('global.form.optional')}`}
                    error={displayError(t, formik, 'marriedTo')}
                    valid={isFieldValid(formik, 'marriedTo', null, initialValues?.marriedTo)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.marriedTo}
                    disabled={!userCanEdit}
                  />
                  <DateInput
                    id="birthDate"
                    name="birthDate"
                    className={utils.cn(['m-t-25', styles.dateInput])}
                    label={t('profile.personalInfo.form.birthDate')}
                    error={displayError(t, formik, 'birthDate')}
                    valid={isFieldValid(formik, 'birthDate', null, initialValues?.birthDate)}
                    onChange={(date) => {
                      formik.setFieldValue('birthDate', date);
                    }}
                    onBlur={formik.handleBlur}
                    value={birthDateValue}
                    disabled={!userCanEdit}
                  />
                  <CityInput
                    id="birthCity"
                    name="birthCity"
                    className="m-t-25"
                    label={`${t('tenant.crud.form.birthCity')} ${t('global.form.optional')}`}
                    error={displayError(t, formik, 'birthCity')}
                    valid={isFieldValid(formik, 'birthCity', null, initialValues?.birthCity)}
                    onAddressSelect={(value) => {
                      formik.setFieldValue('birthCity', value);
                    }}
                    value={formik.values.birthCity}
                    disabled={!userCanEdit}
                  />
                  <SelectInput
                    id="birthCountry"
                    name="birthCountry"
                    className={utils.cn(['m-t-25', styles.largeInput])}
                    label={`${t('profile.personalInfo.form.birthCountry')} ${t('global.form.optional')}`}
                    options={countryOptions}
                    error={displayError(t, formik, 'birthCountry')}
                    valid={isFieldValid(formik, 'birthCountry', null, initialValues?.birthCountry)}
                    onChange={(value) => {
                      formik.setFieldValue('birthCountry', value.value);
                      // As of now, set the birthDepartment to 991 (Europe) if the birthCountry is not France
                      if (value.value !== 'FR') formik.setFieldValue('birthDepartment', '991');
                      else formik.setFieldValue('birthDepartment', null);
                    }}
                    onBlur={formik.handleBlur}
                    value={countryOptions.find((birthCountry) => birthCountry.value === formik.values.birthCountry)}
                    isClearable
                    disabled={!userCanEdit}
                  />
                  { formik.values.birthCountry === 'FR' && (
                    <SelectInput
                      id="birthDepartment"
                      name="birthDepartment"
                      className={utils.cn(['m-t-25', styles.largeInput])}
                      label={`${t('profile.personalInfo.form.birthDepartment')} ${t('global.form.optional')}`}
                      options={birthDepartmentOptions}
                      error={displayError(t, formik, 'birthDepartment')}
                      valid={isFieldValid(formik, 'birthDepartment', null, initialValues?.birthDepartment)}
                      onChange={(value) => {
                        formik.setFieldValue('birthDepartment', value.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={birthDepartmentOptions.find((option) => option.value === formik.values.birthDepartment)}
                      isClearable
                      disabled={!userCanEdit}
                    />
                  )}
                  <SelectInput
                    id="nationality"
                    name="nationality"
                    className={utils.cn(['m-t-25', styles.largeInput])}
                    label={`${t('profile.personalInfo.form.nationality')} ${t('global.form.optional')}`}
                    options={nationalityOptions}
                    error={displayError(t, formik, 'nationality')}
                    valid={isFieldValid(formik, 'nationality', null, initialValues?.nationality)}
                    onChange={(value) => {
                      formik.setFieldValue('nationality', value?.value || null);
                    }}
                    onBlur={formik.handleBlur}
                    value={nationalityOptions.find((nationality) => nationality.value === formik.values.nationality)}
                    isClearable
                    disabled={!userCanEdit}
                  />
                  <AddressInput
                    id="address"
                    name="address"
                    className={utils.cn(['m-t-25', styles.largeInput])}
                    label={`${t('profile.personalInfo.form.address')} ${t('global.form.optional')}`}
                    error={formik?.submitCount > 0 && t(formik?.errors?.address)}
                    valid={isFieldValid(formik, 'address', null, initialValues?.address)}
                    onAddressSelect={(value) => {
                      formik.setFieldValue('address', value);
                    }}
                    value={formik.values?.address || null}
                    disabled={!userCanEdit}
                  />

                  <h2 className="m-t-50 m-b-30">
                    {t('profile.personalInfo.bankingInfo')}
                  </h2>
                  <IbanInput
                    id="iban"
                    name="iban"
                    className="m-t-25"
                    label={`${t('profile.personalInfo.form.iban')} ${t('global.form.optional')}`}
                    error={displayError(t, formik, 'iban')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.iban}
                    disabled={!userCanEdit}
                    editMode={isEditing && !!initialValues.iban}
                    ref={ibanRef}
                  />
                  <TextInput
                    type="hidden"
                    id="taxCountry"
                    name="taxCountry"
                    label={t('tenant.crud.form.taxCountry')}
                    value={formik.values?.taxCountry}
                    disabled={!userCanEdit}
                  />
                </>
              ) : null}
              {isFormError
                ? <Message variant="error" className="m-t-30" content={t('global.form.errors.localGeneric')} />
                : null}

              {userCanEdit && (
                <div className={styles.submit}>
                  <Button
                    type="submit"
                    size="large"
                    loading={formik.isSubmitting}
                    label={t('profile.loginInfo.form.submit')}
                  />
                  <p
                    tabIndex={0}
                    role="button"
                    onKeyDown={null}
                    className={styles.cancel}
                    onClick={() => {
                      formik.resetForm();
                      if (isEditing && !!initialValues.iban) ibanRef.current?.resetEditMode();
                      utils.toast.info(t('global.form.cancelMessage'));
                      scrollContentToTop();
                    }}
                  >
                    {t('global.cancel')}
                  </p>
                </div>
              )}
            </>
          )
            : (
              <div>
                <p className="m-t-25 p-2-500">
                  {formik.values.email !== me.email
                    ? t('owners.form.existingOwnerInfo')
                    : t('owners.form.sameUser')}
                </p>
                {formik.values.email !== me.email && (
                  <Button
                    type="button"
                    variant="primary"
                    className="m-t-10"
                    loading={sendInviteMutation.isLoading}
                    onClick={() => sendInviteMutation.mutate(formik.values.email)}
                  >
                    {t('global.sendInvite')}
                  </Button>
                )}
              </div>
            )}
        </form>
      </div>
      <div>
        {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
      </div>
    </div>
  );
}

TenantForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  tenantQuery: PropTypes.shape().isRequired,
};

export default TenantForm;
