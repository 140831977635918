import {
  useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Components
import {
  Picto,
  utils,
  TextInput,
  Message,
  Button,
  ToggleInput,
  DateInput,
} from 'ui-library-unlocker';

// Services
import { updateLeaseHousingBenefits } from '../../../services/lease';

// Hooks
import { useAppContext } from '../../../store/context';
import useRoles from '../../../hooks/useRoles';

// Utils
import { displayError, errorFocusSubmit } from '../../../utils/forms/form';

import styles from './LeaseHousingBenefits.module.scss';

function LeaseHousingBenefits({
  isLoading,
  error,
  hasBeenRevoked,
  bankInfo,
  bankInfoRefetch,
  lease,
}) {
  const { t } = useTranslation();
  const { context: { user, me } } = useAppContext();
  const { isUserTenant, isUserAdmin } = useRoles();

  const {
    rentPaymentDay = 5,
    managerUid,
    uid,
  } = lease || {};

  const minDate = useMemo(() => {
    const newDate = new Date();
    const rentPaymentMinus3Days = new Date(newDate.getFullYear(), newDate.getMonth(), rentPaymentDay - 3);
    if (newDate >= rentPaymentMinus3Days) {
      newDate.setMonth(newDate.getMonth() + 1);
    }
    newDate.setDate(1);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }, [rentPaymentDay]);

  const getDefaultDate = useCallback((month, year) => {
    if (month && year) return new Date(year, month - 1, 1);
    return minDate;
  }, [minDate]);

  const userHasManagementRights = useMemo(() => {
    if (isUserTenant) return false;
    if (isUserAdmin) return true;
    if (!managerUid || !user?.username || !me?.aclMatrix?.companies) return false;
    if (user.username === managerUid) return true;
    if (me.aclMatrix.companies.some((company) => company.uid === managerUid)) return true;
    return false;
  }, [isUserTenant, user, managerUid, isUserAdmin, me]);

  const initialValues = useMemo(() => {
    const housingBenef = bankInfo?.housingBenefits;
    if (bankInfo?.paymentMethod === 'direct_debit' && housingBenef) {
      return {
        enabled: housingBenef.enabled,
        amount: utils.centsToEuro(housingBenef.amount),
        date: getDefaultDate(housingBenef.month, housingBenef.year),
      };
    }
    return {
      enabled: false,
      amount: 0,
      date: getDefaultDate(),
    };
  }, [bankInfo]);

  const validationSchema = useMemo(() => yup.object().shape({
    enabled: yup.boolean(),
    amount: yup.number().when('enabled', {
      is: true,
      then: yup.number().required(t('lease.add.form.general.fields.amountRequired')),
    }),
    date: yup.date().when('enabled', {
      is: true,
      then: yup.date().required(t('lease.add.form.general.fields.dateRequired')),
    }),
  }), []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (bankInfo?.paymentMethod === 'direct_debit') {
        housingBenefitsMutation.mutate({
          enabled: values.enabled,
          amount: utils.euroToCents(values.amount),
          month: values.date.getMonth() + 1,
          year: values.date.getFullYear(),
        });
      }
    },
  });

  const housingBenefitsMutation = useMutation({
    mutationFn: (data) => updateLeaseHousingBenefits(uid, data),
    onSuccess: () => {
      utils.toast.success(t('lease.housingBenefits.editSuccess'));
      bankInfoRefetch?.();
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          formik.setErrors(err.response.data?.errors);
          if (
            err.response.data?.message === 'PAYMENT_HOUSING_BENEFITS_PAYMENT_DUE_STATUS_EXCEPTION'
            || err.response.data?.message === 'PAYMENT_HOUSING_BENEFITS_AMOUNT_ERROR_EXCEPTION'
          ) {
            utils.toast.error(t(`lease.housingBenefits.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.global'));
          }
          break;
        }
        case 403: {
          if (err.response.data?.message === 'PAYMENT_HOUSING_BENEFITS_MANAGER_NOT_ALLOWED_EXCEPTION') {
            utils.toast.error(t(`lease.housingBenefits.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.forbidden'));
          }
          break;
        }
        case 500: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
      }
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const isEditable = useMemo(() => {
    if (hasBeenRevoked) return false;
    if (bankInfo?.paymentMethod !== 'direct_debit') return false;
    return userHasManagementRights;
  }, [hasBeenRevoked, userHasManagementRights]);

  const isEditing = useMemo(() => (
    JSON.stringify(formik.values) !== JSON.stringify(initialValues)
  ), [formik.values, initialValues]);

  if (error) {
    return (
      <Message
        variant="info"
        className="m-t-50"
        content={t('global.form.errors.global')}
      />
    );
  }

  if (isLoading) {
    return (
      <Picto className="m-t-50" icon="loading" width={30} color="var(--color-secondary)" />
    );
  }

  return (
    <div className="m-t-50">
      <form onSubmit={errorFocusSubmit(formik.handleSubmit)}>
        <ToggleInput
          id="enabled"
          name="enabled"
          label={t('lease.housingBenefits.toggleLabel')}
          checked={!!formik.values.enabled}
          onChange={(check) => formik.setFieldValue('enabled', check)}
          disabled={!isEditable}
        />
        {formik.values.enabled && (
        <>
          <TextInput
            min="0"
            type="number"
            id="amount"
            name="amount"
            className={utils.cn(['m-t-25', styles.mediumInput])}
            label={t('lease.housingBenefits.amount')}
            value={formik.values.amount}
            disabled={!isEditable}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={displayError(t, formik, 'amount')}
            info={t('lease.housingBenefits.amountInfo')}
          />
          <DateInput
            id="date"
            name="date"
            className={utils.cn(['m-t-25', styles.mediumInput])}
            label={t('lease.housingBenefits.date')}
            error={displayError(t, formik, 'date')}
            onChange={(date) => formik.setFieldValue('date', date)}
            onBlur={formik.handleBlur}
            value={formik.values.date}
            monthYearPicker
            minDate={minDate}
            disabled={!isEditable}
          />
        </>
        )}
        <div className={utils.cn([styles.submit, 'm-t-30'])}>
          {isEditable && (
          <>
            <Button
              type="submit"
              loading={formik.isSubmitting}
              disabled={!isEditing}
              label={t('global.validate')}
            />
            {isEditing && (
              <p
                tabIndex={0}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                onKeyDown={null}
                className={styles.cancel}
                onClick={formik.resetForm}
              >
                {t('global.cancel')}
              </p>
            )}
          </>
          )}
        </div>
      </form>
    </div>
  );
}

LeaseHousingBenefits.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  hasBeenRevoked: PropTypes.bool,
  bankInfo: PropTypes.shape({
    paymentMethod: PropTypes.string,
    housingBenefits: PropTypes.shape({
      enabled: PropTypes.bool,
      amount: PropTypes.number,
      month: PropTypes.number,
      year: PropTypes.number,
    }),
  }),
  bankInfoRefetch: PropTypes.func,
  lease: PropTypes.shape({
    managerUid: PropTypes.string,
    uid: PropTypes.string,
    rentPaymentDay: PropTypes.number,
  }),
};

LeaseHousingBenefits.defaultProps = {
  isLoading: true,
  error: false,
  hasBeenRevoked: false,
  bankInfo: {},
  bankInfoRefetch: null,
  lease: {},
};

export default LeaseHousingBenefits;
