/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useMatch } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import {
  Button,
  Tag,
  utils,
} from 'ui-library-unlocker';

// Components
import Modal from '../../molecules/Modal/Modal';

// Services
import { submitPaymentAccountEnrolment } from '../../../services/paymentAccount';

// Utils
import { showModal, hideModal } from '../../../utils/modal';

// Constants
import { COMPLETION_STATUS } from '../../../utils/constants';

// import styles from './PersonPaymentAccount.module.scss';

function PersonPaymentAccountSent({ children }) {
  return <b>{children}</b>;
}

function PersonPaymentAccount({ entity, dataQuery }) {
  const { t } = useTranslation();

  const match = useMatch(`/${entity}/:id`);

  const confirmEzynessModalId = 'confirm-ezyness-submit';

  const submitToEzynessMutation = useMutation({
    mutationFn: submitPaymentAccountEnrolment,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('profile.paymentAccount.success'));
          hideModal(confirmEzynessModalId);
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 401: {
            utils.toast.error(t('global.form.errors.forbidden'));
            break;
          }
          case 424: {
            switch (err?.response?.data?.message) {
              case 'PAYMENT_TAGPAY_IS_IN_PLS_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorTagPay'));
                break;
              }
              case 'PAYMENT_TAGPAY_SERVER_EXCEPTION': {
                const { errors } = err?.response?.data || {};
                utils.toast.error(
                  t('profile.paymentAccount.errorTagPay')
                  + Array.isArray(errors) ? errors.map((msg) => `\n${msg}`) : '',
                );
                break;
              }
              case 'PAYMENT_TAGPAY_BAD_REQUEST_EXCEPTION': {
                const { errors } = err?.response?.data || {};
                utils.toast.error(t('profile.paymentAccount.badRequestEzyness', {
                  fields: errors ? errors.entries(([key, value]) => `\n${key} => ${value}`) : 'unknown',
                }));
                break;
              }
              case 'PAYMENT_TAGPAY_RECIPIENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorTagPayRecipient'));
                break;
              }
              default:
                utils.toast.error(t('global.form.errors.global'));
                break;
            }
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.global'));
            break;
        }
      }
    },
  });

  const onboardingStatus = useMemo(
    () => {
      if (submitToEzynessMutation?.isSuccess) return COMPLETION_STATUS.ENROLMENT_PENDING;
      if (!dataQuery?.data?.data?.onboardingStatus) return null;
      return dataQuery.data?.data.onboardingStatus;
    },
    [dataQuery?.data?.data?.onboardingStatus, submitToEzynessMutation?.isSuccess],
  );

  const enrolmentStatus = useMemo(() => {
    if (!onboardingStatus) return null;
    switch (onboardingStatus) {
      case COMPLETION_STATUS.PENDING:
        return {
          variant: 'tertiary',
          state: '1',
        };
      case COMPLETION_STATUS.PROFILE_DONE:
        return {
          variant: 'primary',
          state: '1',
        };
      case COMPLETION_STATUS.ENROLMENT_REQUESTED:
        return {
          variant: 'tertiary',
          state: '2',
        };
      case COMPLETION_STATUS.ENROLMENT_PENDING:
        return {
          variant: 'tertiary',
          state: '3',
        };
      case COMPLETION_STATUS.ENROLMENT_CHALLENGED:
        return {
          variant: 'tertiary',
          state: '4',
        };
      case COMPLETION_STATUS.ENROLMENT_DENIED:
        return {
          variant: 'error',
          state: '4',
        };
      case COMPLETION_STATUS.COMPLETED:
      case COMPLETION_STATUS.ENROLMENT_BYPASSED:
        return {
          variant: 'success',
          state: '5',
        };
      case COMPLETION_STATUS.BLOCKED:
        return {
          variant: 'success',
          state: '6',
        };
      default:
        return {
          variant: 'error',
        };
    }
  }, [onboardingStatus]);

  const handleSubmit = useCallback(() => {
    if (!match?.params?.id) return utils.toast.error(t('global.form.errors.global'));
    return submitToEzynessMutation.mutate(match?.params?.id);
  }, [match?.params?.id, submitToEzynessMutation]);

  if (!enrolmentStatus) return <div className="m-t-50">{t('global.loading')}</div>;

  return (
    <div className="m-t-20">
      <Tag
        className="m-r-5"
        label={t(`user.tabs.paymentAccount.labelStatus.${onboardingStatus}`)}
        variant={enrolmentStatus?.variant}
        size="large"
      />
      <div className="m-t-50">
        {['1', '2', '4', '6'].includes(enrolmentStatus?.state) && (
        <Button
          size="large"
          disabled={['1', '6'].includes(enrolmentStatus?.state)}
          onClick={() => showModal(confirmEzynessModalId)}
        >
          {t('user.tabs.paymentAccount.submitBtn')}
        </Button>
        )}
        {['3'].includes(enrolmentStatus?.state) && (
        <div>
          <Trans
            i18nKey="user.tabs.paymentAccount.enrolmentSent"
            t={t}
            values={{ timestamp: format(new Date(), 'dd/MM/yyyy') }}
            components={[<PersonPaymentAccountSent />]}
          />
        </div>
        )}
        {['5'].includes(enrolmentStatus?.state) && (
        <div>
          <Trans
            i18nKey="user.tabs.paymentAccount.enrolmentComplete"
            t={t}
            values={{ timestamp: format(new Date(), 'dd/MM/yyyy') }}
            components={[<PersonPaymentAccountSent />]}
          />
        </div>
        )}
      </div>
      <Modal
        id={confirmEzynessModalId}
        title={t('profile.paymentAccount.confirmMessage')}
        size="large"
        onClose={() => {
          hideModal(confirmEzynessModalId);
        }}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={submitToEzynessMutation?.isLoading}
          onClick={handleSubmit}
        >
          {t('global.validate')}
        </Button>
      </Modal>
    </div>
  );
}

PersonPaymentAccount.propTypes = {
  entity: PropTypes.string.isRequired,
  dataQuery: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({
        onboardingStatus: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default PersonPaymentAccount;
