import { useCallback, useEffect } from 'react';
import {
  WithHeader,
  Button,
  utils,
  Picto,
} from 'ui-library-unlocker';

import { useMediaQuery } from 'react-responsive';
import { useNavigate, Link } from 'react-router-dom';
import { t } from 'i18next';

import cn from 'ui-library-unlocker/src/utils/cn';
import { showModal } from '../../utils/modal';
import styles from './Support.module.scss';
import NewFeedbackModal from './NewFeedbackModal';

const feedbackModal = 'feedBackModal';

function SupportView() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('support.browserTitle');
  }, [t]);

  const renderNav = useCallback(() => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/login')}
            label={t('login.title')}
            size={isMobile ? 'small' : 'medium'}
          />
        </li>
      </ul>
    </nav>
  ), [isMobile, navigate, t]);

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className={utils.cn([
        styles.searchPublicView,
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >

      <div className={styles.black}>
        <div className={styles.info}>
          <h1>{t('support.title')}</h1>
          <h2>{t('support.subTitle')}</h2>
        </div>
        <div className={styles.cat}>
          <div className={cn([styles.ear, styles.earLeft])} />
          <div className={cn([styles.ear, styles.earRight])} />
          <div className={styles.face}>
            <div className={cn([styles.eye, styles.eyeLeft])}>
              <div className={styles.eyePupil} />
            </div>
            <div className={cn([styles.eye, styles.eyeRight])}>
              <div className={styles.eyePupil} />
            </div>
            <div className={styles.muzzle} />
          </div>

        </div>
        <div className={styles.subInfo}>
          <p>{t('support.help')}</p>
          <p>{t('support.feedback')}</p>
          <p>
            <Button
              onClick={() => showModal(feedbackModal)}
              size="large"
              label="Signaler un problème"
            />
          </p>
        </div>
      </div>
      <NewFeedbackModal
        id={feedbackModal}
      />
      <footer className={styles.footer}>
        {!isMobile && (
        <>
          <Link to="/register" className={styles.footerLogo}>
            <Picto icon="logo-horizontal" width={160} color="var(--color-black)" />
          </Link>

          <div className={styles.socialNetworks}>
            <a href="https://www.facebook.com/profile.php?id=100085865975036" target="_blank" rel="noopener noreferrer">
              <Picto icon="facebook-frame" width={20} color="var(--color-black)" />
            </a>
            <a href="https://fr.linkedin.com/company/unlockerapp" target="_blank" rel="noopener noreferrer">
              <Picto icon="linkedin-frame" width={24} color="var(--color-black)" />
            </a>
          </div>
        </>
        )}

        <div className={styles.userCtas}>
          <a
            className={styles.storePill}
            href="https://apps.apple.com/us/app/unlocker/id1667221898"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/cta-app-store.png"
              alt="Download on the App Store"
            />
          </a>
          <a
            className={styles.storePill}
            href="https://play.google.com/store/apps/details?id=com.unlockerapp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="m-l-20"
              src="/images/cta-google-play.png"
              alt="Get it on Google Play"
            />
          </a>
        </div>

      </footer>
    </WithHeader>
  );
}

SupportView.propTypes = {
};

SupportView.defaultProps = {};

export default SupportView;
