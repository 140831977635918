import * as yup from 'yup';
import { validatePhoneNumber } from '../validation';
import addressSchema from './addressSchema';

const twentyYearsBackDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 20);
  return date;
};

export const tenantInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  secondName: '',
  birthName: '',
  mobilePhoneCountryCode: null,
  mobilePhoneNumber: '',
  phoneCountryCode: null,
  phoneNumber: null,
  title: null, // "MR" "MS"
  gender: null, // "MALE" "FEMALE" "NONE"
  marriedTo: '',
  birthDate: twentyYearsBackDate(),
  birthCity: '', // string
  birthCountry: '', // CODE COUNTRY
  birthDepartment: '', // string (numéro de département)
  nationality: '', // CODE COUNTRY
  address: {
    street: '',
    streetDetails: null,
    streetNumber: null,
    zipCode: '',
    latitude: null,
    longitude: null,
    countryCode: '',
    city: '',
  },
  iban: '',
  taxCountry: 'FR',
};

const tenantSchema = yup.object().shape({
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  lastName: yup.string().required('global.form.errors.required'),
  firstName: yup.string().required('global.form.errors.required'),
  birthName: yup.string().nullable(),
  secondName: yup.string().nullable(),
  mobilePhoneCountryCode: yup.string().required('global.form.errors.required').nullable(),
  mobilePhoneNumber: validatePhoneNumber('mobilePhoneNumber', 'mobilePhoneCountryCode')
    .required('global.form.errors.required'),
  phoneCountryCode: yup.string().nullable(),
  phoneNumber: validatePhoneNumber('phoneNumber', 'phoneCountryCode'),
  title: yup.string()
    .oneOf(['mr', 'ms', 'undefined', null], 'global.form.errors.invalid')
    .nullable(),
  gender: yup.string()
    .oneOf(['male', 'female', 'none', null], 'global.form.errors.invalid')
    .nullable(),
  marriedTo: yup.string().nullable(),
  birthDate: yup
    .date()
    .max(
      new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
      'profile.personalInfo.errors.underaged',
    )
    .required('global.form.errors.required')
    .nullable(),
  birthCity: yup.string().nullable(),
  birthCountry: yup.string().nullable(),
  birthDepartment: yup.string().nullable(),
  nationality: yup.string().nullable(),
  address: yup.object().when('$isNew', {
    is: true,
    then: yup.object().nullable(),
    otherwise: addressSchema.nullable(),
  }),
  iban: yup.string()
    .test({
      name: 'iban-format',
      test: function test(value, ctx) {
        if (!value) return true;
        if (!value.startsWith('FR')) return ctx.createError({ message: 'global.form.errors.ibanFormat' });
        if (value.length !== 27) return ctx.createError({ message: 'global.form.errors.iban' });
        return true;
      },
    })
    .nullable(),
  taxCountry: yup.string().required('global.form.errors.required').nullable(),
});

export default tenantSchema;
