import api from './_client';

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getCompanies = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[onboardingStatus][--in--]': filters?.onboardingStatus?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get('/person/profile/companies', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getCompany = (uid) => api.get(`/person/profile/companies/${uid}`);

export const getCompanyFromAdmin = (uid) => api.get(`/person/admin/profile/companies/${uid}`);

export const createCompany = (data) => api.post('/person/profile/companies', data);

export const updateCompany = (uid, data) => api.patch(`/person/profile/companies/${uid}`, data);

export const deleteCompany = (uid) => api.delete(`/person/profile/companies/${uid}`);

export const checkCompanyIsRealEstate = () => api.get('/person/profile/companies/has-real-estate-agency');

export const createCompanyDocument = ({
  type,
  name,
  documentUid,
  companyUID,
}) => api.post('/person/documents', {
  type, name, documentUid, companyUID,
});

export const deleteCompanyDocument = (documentUid) => api.delete(`/person/documents/${documentUid}`);

export const requestCompanyEnrolment = (companyUID) => api.post(`/person/enrolment/request/company/${companyUID}`);
