// eslint-disable-next-line no-unused-vars
export const displayError = (t, { errors, touched }, field, inputType = 'text', options = {}) => {
  // console.log(errors, field, touched);
  if (errors == null || touched == null) return null;
  if (options?.errorIfUntouched && errors[field]) {
    if (typeof errors[field] === 'object' && Object.keys(errors[field]).length > 0) {
      return t(errors[field][Object.keys(errors[field])[0]]);
    }
    return t(errors[field]);
  }
  if (touched[field] && errors[field]) return t(errors[field]);
  return null;
};

export const isFieldValid = ({ errors, touched, values }, field, options, initialValue = null) => {
  if (options?.inputType === 'select') {
    return values[field] && !errors[field];
  }
  if (options?.inputType === 'boolean') {
    if (touched[field] && (values[field] === true || values[field] === false)) return true;
    return false;
  }
  if (initialValue && values[field] === initialValue) return false;
  if (!values[field]) return false;
  if (touched[field] && !errors[field]) {
    return true;
  }
  return false;
};

export const errorFocusSubmit = (callback) => (e) => {
  callback(e);
  setTimeout(() => {
    document.querySelector('input[aria-invalid=true]')?.scrollIntoView({ behavior: 'smooth' });
  }, 50);
};
