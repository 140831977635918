import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  utils,
  TextInput,
  SelectInput,
  DateInput,
  Button,
} from 'ui-library-unlocker';

import { getLeases } from '../../../../services/lease';
import { getTenantsByUid } from '../../../../services/tenant';
import { addManualPayment } from '../../../../services/payment';

// Utils
import registerPaymentSchema, { registerPaymentInitialValues } from '../../../../utils/forms/registerPaymentSchema';
import { displayError, isFieldValid } from '../../../../utils/forms/form';

import styles from './RegisterPaymentForm.module.scss';

function RegisterPaymentForm({
  onSuccessfulSubmit,
  menuPortalTarget,
}) {
  const { t } = useTranslation();
  const [leaseQuery, setLeaseQuery] = useState('');
  const [tenantsQuery, setTenantsQuery] = useState('');

  const initialValues = registerPaymentInitialValues;

  const paymentMutation = useMutation({
    mutationFn: (data) => addManualPayment(data),
    onSuccess: () => {
      utils.toast.success(t('dashboard.registerPayment.form.success'));
      onSuccessfulSubmit();
    },
    onError: (err) => {
      switch (err?.response?.data?.message) {
        case 'PAYMENT_LEASE_NOT_FOUND_EXCEPTION':
          utils.toast.error(t('dashboard.registerPayment.form.errors.PAYMENT_LEASE_NOT_FOUND_EXCEPTION'));
          break;
        default:
          utils.toast.error(t('global.form.errors.generic'));
          break;
      }
      formik?.setSubmitting(false);
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registerPaymentSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      paymentMutation.mutate({
        ...values,
        paidAmount: utils.euroToCents(values.paidAmount),
      });
    },
  });

  const {
    data: leasesListData,
    isFetching: leasesListFetching,
  } = useQuery({
    queryKey: ['leases-list-select', leaseQuery],
    queryFn: () => getLeases({
      filters: {
        status: ['signed', 'created'],
        search: [leaseQuery],
      },
    }),
    keepPreviousData: false,
  });

  const handleSearchLease = useCallback(debounce((value) => {
    setLeaseQuery(value);
  }, 500), [setLeaseQuery]);

  const handleGetTenants = useCallback(() => {
    const lease = leasesListData?.data?.collection?.find((el) => el.uid === formik.values.leaseUID);
    return getTenantsByUid({
      filters: {
        uid: lease?.tenants?.join(','),
        search: [tenantsQuery],
      },
    });
  }, [formik.values.leaseUID, leasesListData, tenantsQuery]);

  const {
    data: tenantsListData,
    isFetching: tenantsFetching,
  } = useQuery({
    queryKey: ['tenants-list-select', formik.values.leaseUID, tenantsQuery],
    queryFn: handleGetTenants,
    keepPreviousData: false,
    enabled: !!formik.values.leaseUID,
  });

  const handleSearchTenants = useCallback(debounce((value) => {
    setTenantsQuery(value);
  }, 500), [setTenantsQuery]);

  const leaseOptions = useMemo(() => {
    if (!leasesListData) return [];
    return leasesListData?.data?.collection
      ?.map((lease) => ({
        value: lease.uid,
        label: lease.name,
      }));
  }, [leasesListData]);

  const tenantOptions = useMemo(() => {
    if (!tenantsListData) return [];
    return tenantsListData?.data?.collection
      ?.map((tenant) => ({
        value: tenant.uid,
        label: `${tenant.firstName} ${tenant.lastName}`,
      }));
  }, [tenantsListData]);

  const paymentTypeOptions = useMemo(() => [
    {
      value: 'rent',
      label: t('dashboard.registerPayment.form.paymentType.rent'),
    },
    // {
    //   value: 'deposit',
    //   label: t('dashboard.registerPayment.form.paymentType.deposit'),
    // },
  ], [t]);

  const paymentMethodOptions = useMemo(() => [
    {
      value: 'manual_direct_debit',
      label: t('dashboard.registerPayment.form.paymentMethod.manual_direct_debit'),
    },
    {
      value: 'manual_bank_check',
      label: t('dashboard.registerPayment.form.paymentMethod.manual_bank_check'),
    },
  ], [t]);

  const showForm = useMemo(() => {
    if (!formik.values.leaseUID) return false;
    return true;
  }, [formik.values.leaseUID]);

  const paymentDateValue = useMemo(() => {
    try {
      if (!formik.values.paymentDate) return null;
      return new Date(formik.values.paymentDate);
    } catch {
      return null;
    }
  }, [formik.values.paymentDate]);

  const handleLeaseChange = useCallback((value) => {
    formik.setFieldValue('leaseUID', value);

    // paymentDate
    const today = new Date();
    const lease = leasesListData?.data?.collection?.find((el) => el.uid === value);
    const rentPaymentDay = lease?.rentPaymentDay ?? 5;
    formik.setFieldValue('paymentDate', new Date(today.getFullYear(), today.getMonth(), rentPaymentDay));

    // paidAmount
    formik.setFieldValue('paidAmount', utils.centsToEuro(lease?.rent));

    // tenants
    formik.setFieldValue('payerUID', null);
  }, [formik.values.leaseUID, leasesListData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>

          <SelectInput
            id="leaseUID"
            name="leaseUID"
            className={styles.selectInput}
            label={t('dashboard.registerPayment.form.lease')}
            options={leaseOptions}
            error={displayError(t, formik, 'leaseUID')}
            onChange={(value) => {
              handleLeaseChange(value.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.leaseUID ? leaseOptions.find((el) => el.value === formik.values.leaseUID) : null}
            onInputChange={(value) => handleSearchLease(value)}
            isLoading={leasesListFetching}
            menuPortalTarget={menuPortalTarget}
          />

          {showForm && (
          <>
            <SelectInput
              id="paymentType"
              name="paymentType"
              className={utils.cn(['m-t-25', styles.selectInput])}
              label={t('dashboard.registerPayment.form.paymentType.label')}
              options={paymentTypeOptions}
              error={displayError(t, formik, 'paymentType')}
              onChange={(value) => {
                formik.setFieldValue('paymentType', value.value);
              }}
              onBlur={formik.handleBlur}
              value={paymentTypeOptions.find((el) => el.value === formik.values.paymentType)}
              menuPortalTarget={menuPortalTarget}
            />
            <SelectInput
              id="paymentMethod"
              name="paymentMethod"
              className={utils.cn(['m-t-25', styles.selectInput])}
              label={t('dashboard.registerPayment.form.paymentMethod.label')}
              options={paymentMethodOptions}
              error={displayError(t, formik, 'paymentMethod')}
              onChange={(value) => {
                formik.setFieldValue('paymentMethod', value.value);
              }}
              onBlur={formik.handleBlur}
              value={paymentMethodOptions.find((el) => el.value === formik.values.paymentMethod)}
              menuPortalTarget={menuPortalTarget}
            />
            <DateInput
              id="paymentDate"
              name="paymentDate"
              className={utils.cn(['m-t-25', styles.dateInput])}
              label={t('dashboard.registerPayment.form.paymentDate')}
              error={displayError(t, formik, 'paymentDate')}
              valid={isFieldValid(formik, 'paymentDate', null, initialValues?.paymentDate)}
              onChange={(date) => {
                formik.setFieldValue('paymentDate', date);
              }}
              onBlur={formik.handleBlur}
              value={paymentDateValue}
            />
            <SelectInput
              id="payerUID"
              name="payerUID"
              className={utils.cn(['m-t-25', styles.selectInput])}
              label={t('dashboard.registerPayment.form.tenant')}
              options={tenantOptions}
              error={displayError(t, formik, 'payerUID')}
              onChange={(value) => {
                formik.setFieldValue('payerUID', value.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.payerUID ? tenantOptions.find((el) => el.value === formik.values.payerUID) : null}
              isLoading={tenantsFetching}
              onInputChange={(value) => handleSearchTenants(value)}
              menuPortalTarget={menuPortalTarget}
            />
            <TextInput
              min="0"
              type="number"
              id="paidAmount"
              name="paidAmount"
              className={utils.cn(['m-t-25', styles.largeInput])}
              label={t('dashboard.registerPayment.form.paidAmount')}
              error={displayError(t, formik, 'paidAmount')}
              valid={isFieldValid(formik, 'paidAmount', null, initialValues?.paidAmount)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.paidAmount}
            />
            <div className={styles.submit}>
              <button
                type="button"
                className={styles.cancel}
                onClick={() => {
                  formik.resetForm();
                }}
              >
                <a href="#personal-info">
                  {t('global.cancel')}
                </a>
              </button>
              <Button
                type="submit"
                size="large"
                loading={formik.isSubmitting}
                label={t('global.confirm')}
              />
            </div>
          </>
          )}

        </form>
      </div>
    </div>
  );
}

RegisterPaymentForm.propTypes = {
  onSuccessfulSubmit: PropTypes.func,
  menuPortalTarget: PropTypes.instanceOf(Element),
};

RegisterPaymentForm.defaultProps = {
  onSuccessfulSubmit: () => {},
  menuPortalTarget: null,
};

export default RegisterPaymentForm;
